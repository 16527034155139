import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    selectedFilters: [],
    filteredSkill: [],
    filteredDuration: '',
    filteredCategory:[],
    filteredKeywords: "",
    filteredFocusArea: [],
    filteredEquipments: [],
    filteredIntensity: [],
    filteredLanguage: '',
    filteredSubCategory: '',
    filterSortBy: '',
    filterSearchText: '',
    filteredClassSearchData: [],
    filteredTopKeywords: [],
    loading: false,
    pageCount: 0,
    paginatedData: [],
    classesSearchDataChannel: [],
    tempClassSearchDataChannel: [],
    topKeywords: [],
    filteredMediaType: [],
};

const setSelectedFilters = (state, action) => {
    return updateObject(state, {
        selectedFilters: action.value,
    });
};

const setFilterSkill = (state, action) => {
    return updateObject(state, {
        filteredSkill: action.value,
    });
};

const setFilterDuration = (state, action) => {
    return updateObject(state, {
        filteredDuration: action.value,
    });
};

const setFilterkeywords = (state, action) => {
    return updateObject(state, {
        filteredKeywords: action.value,
    });
};

const setFilterCategory = (state,action) => {
    return updateObject(state, {
        filteredCategory: action.value,
    });
};

const setFilterFocusArea = (state, action) => {
    console.info("pollla",action.value);
    return updateObject(state, {
        filteredFocusArea: action.value,
    });
};

const setFilterEquipments = (state, action) => {
    return updateObject(state, {
        filteredEquipments: action.value,
    });
};

const setFilterIntensity = (state, action) => {
    return updateObject(state, {
        filteredIntensity: action.value,
    });
};

const setClassLanguage = (state, action) => {
    return updateObject(state, {
        filteredLanguage: action.value,
    });
};

const setFilteredSubCategory = (state, action) => {
    return updateObject(state, {
        filteredSubCategory: action.value,
    });
};

const setFilterSort = (state, action) => {
    return updateObject(state, {
        filterSortBy: action.value,
    });
};

const setFilterSearchText = (state, action) => {
    return updateObject(state, {
        filterSearchText: action.value,
    });
};

const setFilteredClassSearchData = (state, action) => {
    return updateObject(state, {
        filteredClassSearchData: action.value,
    });
};

const setFilteredTopKeywords = (state, action) => {
    return updateObject(state, {
        topKeywords: action.value,
    });
};

const setPageCounts = (state, action) => {
    return updateObject(state, {
        pageCount: action.value,
    });
};
const setPaginatedDatas = (state, action) => {
    return updateObject(state, {
        paginatedData: action.value,
    });
};
const setTopKeywordsGlobalSearch = (state, action) => {
    return updateObject(state, {
        topKeywords: action.value,
    });
};

const setMediaTypeGlobalSearch = (state, action) => {
    return updateObject(state, {
        filteredMediaType: action.value,
    });
};



const resetFilter = (state, action) => {
    return updateObject(state, {
        selectedFilters: [],
        filteredSkill: [],
        filteredDuration: "",
        filteredKeywords: "",
        filteredCategory:[],
        filteredFocusArea: [],
        filteredEquipments: [],
        filteredIntensity: [],
        filteredLanguage: "",
        filteredSubCategory: "",
        filterSortBy: "",
        filterSearchText: "",
        filteredClassSearchData: [],
        filteredTopKeywords: [],
        loading: false,
        pageCount: 0,
        paginatedData: [],
        tempClassSearchDataChannel: [],
        filteredMediaType: []
    });
};

const setClassesSearchDataGlobalSearch = (state, action) => {
    return updateObject(state, {
        classesSearchData: action.value,
    });
};
const setTempClassesSearchdataGlobalSearch = (state, action) => {
    return updateObject(state, {
        tempClassSearchDataGlobalSearch: action.value,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_FILTERS_GLOBAL_SEARCH:
            return setSelectedFilters(state, action);
        case actionTypes.SET_FILTER_KEYWORDS_GLOBAL_SEARCH:
            return setFilterkeywords(state, action);
        case actionTypes.SET_FILTERED_CATEGORY_GLOBAL_SEARCH:
            return setFilterCategory(state,action);    
        case actionTypes.SET_FILTER_DURATION_GLOBAL_SEARCH:
            return setFilterDuration(state, action);
        case actionTypes.SET_FILTER_SKILL_GLOBAL_SEARCH:
            return setFilterSkill(state, action);
        case actionTypes.SET_FILTER_FOCUS_AREA_GLOBAL_SEARCH:
            return setFilterFocusArea(state, action);
        case actionTypes.SET_FILTER_EQUIPMENTS_TYPES_GLOBAL_SEARCH:
            return setFilterEquipments(state, action);
        case actionTypes.SET_FILTER_INTENSITY_GLOBAL_SEARCH:
            return setFilterIntensity(state, action);
        case actionTypes.SET_CLASS_LANGUAGE_GLOBAL_SEARCH:
            return setClassLanguage(state, action);
        case actionTypes.SET_FILTER_SORT_GLOBAL_SEARCH:
            return setFilterSort(state, action);
        case actionTypes.SET_FILTER_SEARCH_TEXT_GLOBAL_SEARCH:
            return setFilterSearchText(state, action);
        case actionTypes.SET_FILTERED_SUB_CATEGORY_GLOBAL_SEARCH:
            return setFilteredSubCategory(state, action);
        case actionTypes.SET_FILTERED_CLASS_SEARCH_DATA_GLOBAL_SEARCH:
            return setFilteredClassSearchData(state, action);
        case actionTypes.SET_FILTERED_TOP_KEYWORDS_GLOBAL_SEARCH:
            return setFilteredTopKeywords(state, action);
        case actionTypes.SET_PAGE_COUNT_GLOBAL_SEARCH:
            return setPageCounts(state, action);
        case actionTypes.SET_PAGINATED_DATA_GLOBAL_SEARCH:
            return setPaginatedDatas(state, action);
        case actionTypes.SET_TOP_KEYWORDS_GLOBAL_SEARCH:
            return setTopKeywordsGlobalSearch(state, action);
        case actionTypes.SET_CLASSES_SEARCH_DATA_GLOBAL_SEARCH:
            return setClassesSearchDataGlobalSearch(state, action);
        case actionTypes.SET_TEMP_CLASS_SEARCH_DATA_GLOBAL_SEARCH:
            return setTempClassesSearchdataGlobalSearch(state, action);
        case actionTypes.RESET_FILTERS_GLOBAL_SEARCH:
            return resetFilter(state, action);
        case actionTypes.SET_MEDIA_TYPE_GLOBAL_SEARCH:
            return setMediaTypeGlobalSearch(state, action);
        default:
            return state;
    }
};
export default reducer;
