// reducers/index.js
import { combineReducers } from "redux";

import onDemandClassesReducer from "./ondemand";
import authReducer from "./auth";
import workoutReducer from "./workout";
import subscriptionReducer from "./subscription";
import webPlayerReducer from "./webPlayer";
import clientSettingsReducer from "./clientSettings";
import channelsReducer from "./channels";
import filter from "./filter";
import filterOnDemand from "./filterOnDemand";
import dynamicDeeplink from "./dynamicDeeplink";
import moreClasses from "./moreClasses";
import playList from "./playList";
import ratingReviews from "./ratingReview";
import globalSearch from "./globalSearch";
import subscriptionBundle from "./subscriptionBundle";
import siteConfigurationReducer from "./siteConfig";
import tenantConfig from "./tenantConfig";
import fitRadioReducer from "./fitRadio";
import fitRadioRoyaltyReducer from "./fitRadioRoyaltyReducer";
import filterChannelReducer from "./filterChannel";
import filterGlobalSearch from "./filterGlobalSearch";

const rootReducer = combineReducers({
  // orm:schema.reducer(),
  onDemand: onDemandClassesReducer,
  workout: workoutReducer,
  auth: authReducer,
  subscription: subscriptionReducer,
  webPlayerEvents: webPlayerReducer,
  client: clientSettingsReducer,
  channels: channelsReducer,
  filter: filter,
  moreClasses: moreClasses,
  filterOnDemand: filterOnDemand,
  dynamicDeeplink: dynamicDeeplink,
  playList: playList,
  ratingReviews: ratingReviews,
  globalSearch: globalSearch,
  subscriptionBundle: subscriptionBundle,
  siteConfiguration: siteConfigurationReducer,
  tenantConfig: tenantConfig,
  fitRadio: fitRadioReducer,
  fitRadioRoyalty: fitRadioRoyaltyReducer,
  filterChannel: filterChannelReducer,
  filterGlobalSearch:filterGlobalSearch,
});

export default rootReducer;
