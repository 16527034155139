import React from 'react';
import Close from '@material-ui/icons/Close';
import IconRigntArrow from 'CommonImages/icon-rightArrow';
import config from '../../assets/config.json';
import { Translate } from 'utils/Translate';
import { getRecentSearches } from 'utils/utilityfunctions';
import { LocalStorageKeys } from 'utils/constants';


const SearchSuggestions = ({ handleSubmit }) => {

    const handleClick = (item) => {
        const inputEle = document.getElementById("search-box-input");
        inputEle.value = item;
        handleSubmit()
    };

    const clearSearch = () => {
        localStorage.removeItem(LocalStorageKeys.recentSearches);
    }

    const recentSearches = getRecentSearches()
    return (
        recentSearches.length > 0 &&
        <div className={`recent-search`} aria-labelledby="recent-searches-heading">
            <div className='d-flex' id='recent-searches-heading'>
                <div>{Translate({ id: "ClassListViews.Recentsearches" })}</div>
                <Close onClickCapture={clearSearch} className='makeTextClickable' height={24} width={24} color={config.primaryColor} aria-label="Clear recent searches" role="button" aria-hidden="false" tabIndex="0" />
            </div>
            <ul role="menu">
                {recentSearches?.map((item, index) => (
                    <li className='d-flex recent-search-list' key={index} role="menuitem">
                        <div className="makeTextClickable" onClickCapture={() => handleClick(item)} aria-roledescription='button' tabIndex="0" >
                            {item}
                        </div>
                        <IconRigntArrow id="arrow-4" color={config.primaryColor} cls={"arrow-recent-search"} clickHandler={() => handleClick(item)} aria-roledescription="button" tabIndex="0" />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SearchSuggestions;