/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AudioPlayer } from "components/VideoPlayer/indexAudio";
import IconPlay from "CommonImages/playIcon";
import IconSkip from "CommonImages/icon-skip";
import IconPause from "CommonImages/icon-pause";
import { playerVolume } from "utils/utilityfunctions";

const FitRadioMix = (props) => {
  const {
    fitRadioMixData,
    currentTrackTitle,
    removeTrackPlayTimeout,
    currentTrackArtist,
    onNextClick,
    isVideoPlaying,
    playTrack,
    pauseTrack,
    //setIsTrackPlaying,
    isNextDisabled,
    toggleBox
  } = props;
  const [audioPlaying, setAudioPlaying] = useState(false)

  useEffect(() => {
    if (fitRadioMixData) {
      if (isVideoPlaying === "playing") {
        playTrack();
      } else if (isVideoPlaying === "paused" || isVideoPlaying === "complete") {
        pauseTrack();
        removeTrackPlayTimeout();
      }
    }
  }, [isVideoPlaying]);

  const videoState = () => {

    switch (isVideoPlaying) {
      case "playing":
        return true
      case "pause":
        return false
      case "idle":
        return false
      case "complete":
        return false
      default:
        return false
    }
  }

  const startVideo = (video, jPlayer) => {
    let player = jPlayer;
    if (video != null && player !== null) {
      
      player.on("beforePlay", () => {
        player.setVolume(playerVolume.defaultLevel/playerVolume.multiplier)
      });
     
        player.on("play", () => {
        if (player.getState() === "playing") {
          setAudioPlaying(true)
        }
      });
      
      player.on("pause", (e) => {
        setAudioPlaying(false)
      });

     
      player.on("error", (e) => {
        // eslint-disable-next-line react/no-direct-mutation-state
      });
    }
  };
  return (
    <div className="top-container" data-testid="fit-radio-track">
      <div class="mainTitle">
        <div className="audio-player">
          <AudioPlayer
            id={"audio-player"}
            autoplay={false}
            file={fitRadioMixData?.url}
            startVideo={startVideo}
            tabIndex="0"
          />
        </div>
        <img
          src={fitRadioMixData?.dj?.image_large}
          alt={fitRadioMixData?.title}
          className="Rectangle"
        />
        <div className="track-info makeTextClickable" onClick={() => toggleBox()} role='button' aria-label='Mix' tabIndex="0">
          <span class="track-title">{currentTrackTitle ?? ""}</span>
          <span class="track-artist">{currentTrackArtist ?? ""}</span>
        </div>
        <div className="d-flex">
          {audioPlaying ? (
            <IconPause
              cls={"player-icon makeTextClickable"}
              opacity={"1"}
              pauseTrack={() => {
                pauseTrack();
              }}
              tabIndex="0"
            />
          ) : (
            <IconPlay
             cls={"player-icon makeTextClickable"}
              opacity={videoState() ? "1" : "0.29"}
              playTrack={() => {
                playTrack();
              }}
              tabIndex="0"
            />
          )}
          <IconSkip
            skip={() => {
              (videoState() || audioPlaying) && onNextClick();
            }}
            opacity={
              isNextDisabled ? "0.29" :
              videoState() || audioPlaying ? "1" : "0.29"
            }
            cls={"player-icon makeTextClickable"}
            tabIndex="0"
          />
        </div>
      </div>
    </div>
  );
};

export default FitRadioMix;
