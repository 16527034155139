import React  from 'react';
function FilterIcon(props) {
    const color = props.color
    return (
      <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
        <circle cx="16" cy="16" r="16" fill={color}/>
        <g stroke="#FFF">
            <g transform="translate(6.227 10.3)">
                <path stroke-width="2" stroke-linecap="round" d="M19 3.2h-9M4 3.2H0"/>
                <circle stroke-width="1.5" transform="matrix(-1 0 0 1 13.855 0)" cx="6.927" cy="3" r="2.25"/>
            </g>
            <g transform="matrix(-1 0 0 1 25.227 16.3)">
                <path stroke-width="2" stroke-linecap="round" d="M19 3.2h-9M4 3.2H0"/>
                <circle stroke-width="1.5" transform="matrix(-1 0 0 1 13.855 0)" cx="6.927" cy="3" r="2.25"/>
            </g>
        </g>
    </g>
</svg>
  )
}

export default FilterIcon
