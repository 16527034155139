import * as actionTypes from "./actionTypes";
import api from "../../api";
import * as APIConstants from "../APIConstants";
import userApi from "../../userAPI";
import { Translate } from "../../utils/Translate";
import logger from "../../utils/logger";
import {
  PAGE_CONSTS,
  compareDurationMins,
  isNonEmptyArrayOrObject,
  liveEventStatusForWexer,
  maxInteger,
  openHrefInNewTab,
  setHeaderLanguage,
  aroundTimeBand,
  ClassDurationTimeBand,
  getEventTypeWord,
} from "../../utils/utilityfunctions";
import { isEmpty, isEqual, some } from "lodash";
import { setTenantConfigEventTime } from "./webPlayer";
import differenceBy from "lodash/differenceBy";
import { MAX_API_ATTEMPTS, TenantLockedContentBehaviour } from "utils/constants";
import Routes from "components/Router/Routes";
// import {dummyResponse} from "./dummyResponse"
import {logODClassPerformed,logLiveConnectClassPerformed} from "utils/Localytics"

const config = require("../../assets/config.json");

const findLanguage = () => {
  if (config.embeddedConfig && config.embeddedConfig.isEmbedded) {
    const URL =
      window.location.search && window.location.search !== ""
        ? window.location.search
        : sessionStorage.getItem("embededConfig");

    if (URL && URL !== "" && URL.includes("runtime=embedded")) {
      const params = new Map(
        URL.slice(1)
          .split("&")
          .map((kv) => kv.split("="))
      );
      if (params.has("lang")) {
        return params.get("lang");
      } else return config.language;
    }
  } else return config.language;
};

var moment = require("moment");
require("moment/min/locales.min");
moment.locale(findLanguage());

//const FETCH_LIVESTREAM_SELECTED_DATE
export const saveSelectedDate = (date) => {
  return { type: actionTypes.SAVE_SELECTED_DATE, date };
};

//const FETCH_ONDEMAND_CLASSES
export const savePrevPlayer = (data) => {
  return (dispatch) => {
    logger("player saved");
    dispatch({ type: actionTypes.SAVE_PREV_PLAYER, player: data });
  };
};

export const getCollectionById = () => {
  return (dispatch) => {
    logger(localStorage.getItem("userId"));
    let userID = localStorage.getItem("userId");

    if (userID !== undefined || userID !== null || userID !== "") {
      dispatch(fetchOnDemandClasses(userID));
    } else {
      dispatch(fetchOnDemandClasses());
    }

    // api.setHeader("Authorization","Bearer");
    // let url = APIConstants.collectionByTagAPI + id;

    // if (userId !== undefined || userId !== null || userId !== "") {
    //   url = url + "/" + userId;
    // }
    // dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_COLLECTION_START });
    // api.get(url).then(response => {
    //   if (response.ok) {
    //     logger(response)
    //     dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_COLLECTION_SUCCESS, collection: response.data[0] });
    //   } else {
    //     dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_COLLECTION_FAIL });
    //   }
    // });
  };
};
export const fetchTenantConfig = () => {
  logger("fetchTenantConfig");
  return (dispatch) => {
    const configStartTime = new Date().getTime();
    dispatch({ type: actionTypes.TENENT_CONFIG_LOADING_START });
    // api.setHeader("Authorization","Bearer");
    let url = APIConstants.getTenantConfigAPI + config.name;

    api.get(url).then((response) => {
      if (response.ok) {
        /**
         * RR// July 04, 2020 pc
         * CF-8288 ||Tenant Config added from API
         * Setting Tenent Config in Runtime Memory
         */
        let newConfig = response.data[0];
        logger(newConfig.showClassOfTheDay);
        config.supportEmail = newConfig.contactEmailAddress;
        config.AccessCodeMandatory = newConfig.isAccessCodeMandatory;
        config.language = newConfig.languageTag;
        config.supportedLanguages = newConfig.languageCodes;
        config.showClassOfTheDay = newConfig.showClassOfTheDay;
        config.ShowDiscountTextFeild = newConfig.showDiscountTextField;
        config.EventCardSize = newConfig.eventCardSize;
        config.showChannels = newConfig.showChannels;
        config.connectSource =
          newConfig.connectSource === "vimeo" ? "vimeo" : "wexer";
        config.providerId = newConfig.providerId;
        config.url = newConfig.url;
        config.localytics = newConfig?.localytics?.url;
        config.pricingTableId = newConfig.pricingTableId;
        config.publishableKey = newConfig.publishableKey;
        if (
          newConfig.dateFormat !== null ||
          newConfig.dateFormat !== undefined
        ) {
          config.dateFormat = newConfig.dateFormat;
        }
        config.tenantID = newConfig.tenantID;
        config.copyright = newConfig.copyright;
        config.jwJSPlayerId = newConfig.jwJSPlayerId;
        config.isMarketingConsent = newConfig.isMarketingConsent;
        if (newConfig.mmsSignupLink !== null) {
          config.mmsSignupLink = newConfig.mmsSignupLink;
        }
        if (
          newConfig.ssoRedirectURI !== null ||
          newConfig.ssoRedirectURI !== undefined
        ) {
          config.msalSigninLink = newConfig.ssoRedirectURI;
        }
        if (newConfig.connect) {
          if (newConfig.connect.toLowerCase() === "ondemand") {
            config.showLiveEvent = false;
            config.ShowLiveConnect = true;
          } else if (newConfig.connect.toLowerCase() === "live") {
            config.showLiveEvent = true;
            config.ShowLiveConnect = false;
          } else if (newConfig.connect.toLowerCase() === "both") {
            config.showLiveEvent = true;
            config.ShowLiveConnect = true;
          } else {
            config.showLiveEvent = false;
            config.ShowLiveConnect = false;
          }
        } else {
          config.showLiveEvent = false;
          config.ShowLiveConnect = false;
        }
        dispatch({
          type: actionTypes.TENENT_CONFIG_LOADING_SUCCESS,
          tenantConfig: true,
        });
      } else {
        dispatch({
          type: actionTypes.TENENT_CONFIG_LOADING_FAIL,
          tenantConfig: true,
        });
      }
      const configEndTime = new Date().getTime();
      const time = Math.abs((configStartTime - configEndTime) / 1000);
      dispatch(setTenantConfigEventTime(time));
    });
  };
};

export const fetchOnDemandClasses = (userId) => {
  logger("making collection api call");
  return (dispatch) => {
    // dispatch(fetchTenantConfig());
    // api.setHeader("Authorization","Bearer");
    let url = APIConstants.onDemandCollectionAPI;
    if (userId !== null) {
      url = url + "/" + userId;
    } else {
      url = url + "/";
    }
    dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_START });
    api.get(url).then((response) => {
      if (response.ok) {
        dispatch(fetchOnDemandClassesSuccess(response.data));
      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_FAIL });
      }
    });
  };
};

export const collectionListClasses = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_START });
    const userId = localStorage.getItem("userId");
    const connect = userId ? userApi : api;
    connect.post(APIConstants.collectionList, data).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_COLLECTIONS_LIST,
          collectionListClasses: response.data.data,
        });

        // dispatch(fetchOnDemandClassesSuccess(response.data));
      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_FAIL });
      }
    });
  };
};

export const collectionTitleListClasses = (data) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_START });
    const token = localStorage.getItem("token");
    (token ? userApi : api)
      .post(APIConstants.collectionListTitlesDetails, data)
      .then((response) => {
        if (response && response.ok) {
          dispatch({
            type: actionTypes.COLLECTIONS_TITLE_DETAIL_LIST,
            collectionTitleListClasses: response?.data?.data,
          });
        } else {
          dispatch({ type: actionTypes.FETCH_ONDEMAND_CLASSES_FAIL });
        }
      });
  };
};

export const fetchCotdHomePageData = () => {
  return (dispatch) => {
    let url = APIConstants.getCOTDHomePageData;
    const body = {
      tenantId: config.name,
    };
    const userId = localStorage.getItem("userId");
    const connect = userId ? userApi : api;
    dispatch({ type: actionTypes.FETCH_COTD_DATA_START });
    connect.post(url, body).then((response) => {
      if (response.ok) {
        dispatch(fetchCotdHomePageDataSuccess(response.data));
      } else {
        dispatch({ type: actionTypes.FETCH_COTD_DATA_FAIL });
      }
    });
  };
};

export const loadPoKeys = (attempt = 0) => {
  return (dispatch) => {
    let url = "api/v1/getpoeditorterms";
    dispatch({ type: actionTypes.FETCH_PO_DATA_START });
    api.get(url).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_PO_DATA_SUCCESS,
          languageData: response.data           
         })
      } else {
          attempt = attempt + 1;
          if (attempt < MAX_API_ATTEMPTS) {
            dispatch(loadPoKeys(attempt));
          } else {
            dispatch({ type: actionTypes.FETCH_PO_DATA_FAIL });
          }
      }
    });
  };
};

export const fetchCotdHomePageDataSuccess = (data) => {
  return {
    type: actionTypes.FETCH_COTD_DATA_SUCCESS,
    classes: data,
  };
};
export const fetchOnDemandDeepLinkClass = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_START });
    let url = `${APIConstants.getDeeplinkedChannelClass}`;
    let body = {
      tenantId: config.name,
      tag: id,
    };
    userApi.post(url, body).then((response) => {
      if (response.ok) {
        const channelDetails = response.data.channelDetails;
        const titleDetails = response.data.titleDetails;
        titleDetails["channel"] = channelDetails;
        if (titleDetails) {
          dispatch({
            type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_SUCCESS,
            classes: [titleDetails],
          });
        }
      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_FAIL });
      }
    });
  };
};

export const fetchOnDemandClassesSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ONDEMAND_CLASSES_SUCCESS,
    classes: data,
  };
};

export const fetchConnectDeepLinkClass = (classId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_START });

    let url = `${APIConstants.clubLiveConnectAPI}${config.name}/videos/${classId}`;
    api.get(url).then((response) => {
      if (response.ok) {
        logger(response);
        let clubData = response.data[0];
        const getMaxResolutionThumbnail = (picturesObj) => {
          if (picturesObj.length === 0) {
            return;
          }

          var max = picturesObj[0].height;
          var maxIndex = 0;

          for (var i = 1; i < picturesObj.length; i++) {
            if (picturesObj[i].height > max) {
              maxIndex = i;
              max = picturesObj[i].height;
            }
          }

          return maxIndex;
        };
        let video = {
          tag: clubData.id,
          streamingLink: "",
          creationDate: clubData.created_Time ? clubData.created_Time : "",
          trailerLinkWeb: clubData.trailerLink,
          classDescription: clubData.description,
          className: clubData.name,
          imageLink: clubData.pictures
            ? clubData.pictures.sizes[
                getMaxResolutionThumbnail(clubData.pictures.sizes)
              ].link
            : "",
          durationSecond: clubData.duration,
        };

        dispatch({
          type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_SUCCESS,
          classes: [video],
        });
        logger(response.data);
      } else {
        dispatch({ type: actionTypes.FETCH_ONDEMAND_DEEPLINK_CLASSES_FAIL });
      }
    });
  };
};

export const FetchClubLiveConnect = (page = 1) => {
  return (dispatch) => {
    if (
      //TODO : connect to be picked from which attribute
      "wexer" &&
      "wexer".toLowerCase() === "wexer"
    ) {
      dispatch(
        searchOnDemandClass(
          "",
          "",
          "",
          false,
          1,
          0,
          maxInteger,
          true,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          false,
          config.providerId
        )
      );
    } else {
      if (page === 1)
        dispatch({
          type: actionTypes.FETCH_LIVECONNECT_START,
        });
      let url = `${APIConstants.clubLiveConnectAPI}${config.name}/videos?page=${page}`;
      api.get(url).then((response) => {
        if (response.ok) {
          // if (config.connectSource && config.connectSource.toLowerCase() === "wexer") {
          //   response.data = {
          //     data: modifyFLLResponse(response.data.results)
          //   }
          // }
          dispatch({
            type: actionTypes.FETCH_LIVECONNECT_SUCCESS,
            data: modifyFLLResponse(response.data),
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_LIVECONNECT_FAIL,
            result: response.data,
          });
        }
      });
    }
  };
};

export const FetchLSConnectHome = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_LSCONNECTHOME_START,
    });
    let showFLLOnDemand = false;
    const userId = localStorage.getItem("userId");
    const connect = userId ? userApi : api;
    let url = `${APIConstants.fetchFLLEventsV5}`;
    const body = {
      TenantIds: [config.name],
      upcomingEventCount: 5,
    };

    connect.post(url, body).then((response) => {
      if (response.ok) {
        if ("wexer" && "wexer".toLowerCase() === "wexer") {
          if (config.ShowLiveConnect && response.data.length === 0) {
            showFLLOnDemand = true;
            dispatch(
              searchOnDemandClass(
                "",
                "",
                false,
                1,
                0,
                5,
                false,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                false,
                config.providerId
              )
            );
          } else {
            response.data = {
              //response.data.results
              data: modifyFLLResponse(response.data),
              recordType: "Live",
            };
          }
        }
        if (!showFLLOnDemand) {
          dispatch({
            type: actionTypes.FETCH_LSCONNECTHOME_SUCCESS,
            data: response.data,
          });
        }
      } else {
        dispatch({
          type: actionTypes.FETCH_LSCONNECTHOME_FAIL,
          result: response.data,
        });
      }
    });
  };
};

function modifyFLLResponse(dummyResponse) {
  let today = new Date();
  // for (let i = 0; i < 7; i++) {
  //   weekArr.push(new Date(today.getTime() + i * 24 * 60 * 60 * 1000))
  // }
  var modifiedObj = dummyResponse.reduce((acc, curr, i) => {
    // let weekArr = []
    let activeTime = curr.eventOccurrenceDate + "T" + curr.startTime;
    // var availableTime = today.toISOString().split('T')[0] + 'T' + daily_Time
    // for (let i = 0; i < 7; i++) {
    //   weekArr.push(new Date(new Date(availableTime).getTime() + i * 24 * 60 * 60 * 1000))
    // }
    // let activeTime = curr.schedule.next_Occurence_Time + ".000Z"
    acc.push({
      ...curr,
      isStreaming:
        curr.eventType !== "true-live"
          ? curr.eventDuration
            ? new Date(activeTime).getTime() >= today.getTime()
              ? false
              : new Date(activeTime).getTime() +
                  curr.eventDuration * 60 * 1000 >
                today.getTime()
              ? true
              : false
            : liveEventStatusForWexer(curr)
          : curr.isStreaming,
    });
    return acc;
  }, []);
  return modifiedObj;
}

export const FetchLSConnectList = (page = 1) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_LSCONNECTLIST_START,
    });
    //let url = `${APIConstants.clubLiveConnectAPI}${config.name}/live?page=${page}`;
    let url =
      "wexer" && "wexer".toLowerCase() === "wexer"
        ? `${APIConstants.fetchFLLEventsV5}`
        : `${APIConstants.clubLiveConnectAPI}${config.name}/live`;
    const body = {
      TenantIds: [config.name],
      upcomingEventFrequency: "week",
    };
    const userId = localStorage.getItem("userId");
    const connect = userId ? userApi : api;
    connect.post(url, body).then((response) => {
      if (response.ok) {
        if ("wexer" && "wexer".toLowerCase() === "wexer") {
          response.data = {
            eventData: modifyFLLResponse(response.data),
          };
        }
        dispatch({
          type: actionTypes.FETCH_LSCONNECTLIST_SUCCESS,
          data: response.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_LSCONNECTLIST_FAIL,
          result: response.data,
        });
      }
    });
  };
};

export const resetFetchClubLiveConnect = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_LIVECONNECT_SUCCESS,
      data: "",
    });
  };
};

export const FetchRecordedClasses = (
  event_id,
  nextOccurence = "",
  videoId = "",
  showLoading = true
) => {
  return (dispatch, getState) => {
    if (showLoading) {
      dispatch({
        type: actionTypes.FETCH_RECORDEDCLASSES_START,
      });
    }
    let url = APIConstants.fetchFLLEventsV5;
    const body = {
      TenantIds: [config.name],
      eventId: event_id,
    };

    const {
      onDemand: { RecordedClasses },
    } = getState();
    const userId = localStorage.getItem("userId");
    const connect = userId ? userApi : api;
    connect.post(url, body).then((response) => {
      if (response.ok) {
        if ("wexer" && "wexer" === "wexer" && Array.isArray(response.data)) {
          if (
            !isEqual(RecordedClasses, {
              eventDetail: response.data[0],
              event_id,
              eventViewDate: nextOccurence,
            })
          ) {
            dispatch({
              type: actionTypes.FETCH_RECORDEDCLASSES_SUCCESS,
              data: {
                eventDetail: updateHyperlink(response.data[0]),
                event_id,
                eventViewDate: nextOccurence,
              },
            });
          }
        } else {
          if (
            !isEqual(RecordedClasses, {
              eventDetail: response.data,
              event_id,
              eventViewDate: nextOccurence,
            })
          ) {
            dispatch({
              type: actionTypes.FETCH_RECORDEDCLASSES_SUCCESS,
              data: {
                eventDetail: response.data,
                event_id,
                eventViewDate: nextOccurence,
              },
            });
          }
        }
      } else {
        dispatch({
          type: actionTypes.FETCH_RECORDEDCLASSES_FAIL,
        });
      }
    });
  };
};

const updateHyperlink = (res) => {
  const description = res?.description;
  const container = openHrefInNewTab(description);
  return {
    ...res,
    description: container.innerHTML,
  };
};

/**
 * Nikhil Gupta 08-09-2020
 * Get video details when the video is clicked on My Activity page
 * @param {*} event_id
 * @param {*} videoId
 */
export const FetchLSVideoDetails = (event_id, videoId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_LSVIDEODETAILS_START,
    });
    let url = `${APIConstants.clubLiveConnectAPI}${config.name}/live_event/videos/${videoId}`;
    api.get(url).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_LSVIDEODETAILS_SUCCESS,
          data: { ...response.data, event_id, videoId },
        });
        if (response.data.transcode_Status !== "complete") {
          dispatch({
            type: actionTypes.VIDEO_ERROR,
            error: Translate({ id: "liveStream.TranscodingError" }),
          });
        }
      } else {
        dispatch({
          type: actionTypes.FETCH_LSVIDEODETAILS_FAIL,
        });
      }
    });
  };
};
export const FetchPlayLink = (classId) => () => {
  const body = {
    tenantId: config.name,
    tag: classId,
  };
  return userApi.post(APIConstants.playbackURL, body);
};
export const setStrimingLink = (data) => (dispatch) => {
  const dispatchData = data
    ? { type: actionTypes.FETCH_PLAYBACK_STREAMINGLINK_SUCCESS, data }
    : { type: actionTypes.FETCH_STREAMINGLINK_FAIL };
  dispatch(dispatchData);
};
const getStreamingLink = (playList) => {
  return new Promise((res, rej) => {
    let curStreamingLink;
    const getLink = some(playList, async (item) => {
      const body = {
        tenantId: config.name,
        tag: item.playListItemId,
      };
      let url = APIConstants.playbackURL;
      curStreamingLink = await userApi.post(url, body).then((response) => {
        if (response.ok) {
          return response.data.streamingLink;
        } else return false;
      });
      return curStreamingLink ? true : false;
    });

    return curStreamingLink;
  });
};
export const CheckPlayBackList = (playList) => (dispatch, getState) => {
  const {
    onDemand: { StreamingVideoLink },
  } = getState();
  let streamingLink;
  getStreamingLink(playList).then((link) => {
    console.info(link);
  });
};

export const fetchStreamingLinkPlayList = (classId, notDeepLink = false) => {
  return (dispatch, getState) => {
    const {
      onDemand: { StreamingVideoLink },
    } = getState();
    // error fixing in deeplink case
    if (!notDeepLink) getLinkPlayList(classId, dispatch, StreamingVideoLink);
    else {
      if (!isEmpty(StreamingVideoLink)) {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_START,
        });
      } else getLinkPlayList(classId, dispatch);
    }
  };
};

const getLinkPlayList = (classId, dispatch, StreamingVideoLink) => {
  if (!isEmpty(StreamingVideoLink)) {
    dispatch({
      type: actionTypes.FETCH_STREAMINGLINK_START,
    });
  }
  const body = {
    tenantId: config.name,
    tag: classId,
  };
  let url = APIConstants.playbackURL;
  userApi.post(url, body).then((response) => {
    if (response.ok) {
      dispatch({
        type: actionTypes.PlayList_FETCH_PLAYBACK_STREAMINGLINK_SUCCESS,
        data: response.data.streamingUrl,
        tracks: response.data.tracks,
      });
    } else {
      dispatch({
        type: actionTypes.FETCH_STREAMINGLINK_FAIL,
      });
    }
  });
};

export const FetchPlayBackLink = (classId, notDeepLink = false, navigate) => {
  return (dispatch, getState) => {
    const {
      onDemand: { StreamingVideoLink },
    } = getState();
    // error fixing in deeplink case
    if (!notDeepLink) getLink(classId, navigate, dispatch, StreamingVideoLink);
    else {
      if (!isEmpty(StreamingVideoLink)) {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_START,
        });
      } else getLink(classId, navigate, dispatch, StreamingVideoLink);
    }
  };
};

const getLink = (classId, navigate, dispatch, StreamingVideoLink) => {
  if (!isEmpty(StreamingVideoLink)) {
    dispatch({
      type: actionTypes.FETCH_STREAMINGLINK_START,
    });
  }
  const externalUserId = localStorage.getItem("ExternalUserId");
  let body = {
    tenantId: config.name,
    tag: classId,
  };
  body = externalUserId ? { ...body, externalUserId } : body;

  let url = APIConstants.playbackURL;

  userApi.post(url, body).then((response) => {
    if (response.ok) {
      dispatch({
        type: actionTypes.FETCH_PLAYBACK_STREAMINGLINK_SUCCESS,
        data: response.data.streamingUrl,
        tracks: response.data.tracks,
      });
    } else if (response.status === 403) {
      const lockedBehaviour = config.lockedContentBehaviour;
      switch (lockedBehaviour) {
        case TenantLockedContentBehaviour.show_popup:
          navigate(Routes.accountPage);
          break;
        case TenantLockedContentBehaviour.show_subscription_options:
          navigate("/subscribe");
          break;
        case TenantLockedContentBehaviour.do_nothing:
          //do nothing
          break;

        default:
          navigate(Routes.accountPage);
          break;
      }
      dispatch({
        type: actionTypes.FETCH_STREAMINGLINK_FAIL,
      });
    } else {
      dispatch({
        type: actionTypes.FETCH_STREAMINGLINK_FAIL,
      });
    }
  });
};

export const FetchStreamingLink = (event_id, navigate) => {
  return (dispatch) => {
    let userId = localStorage.getItem("userId");
    // dispatch({
    //   type: actionTypes.FETCH_STREAMINGLINK_START,
    // });
    const body = {
      TenantId: config.name,
      EventId: event_id,
    };
    const connect = userId ? userApi : api;
    let url = APIConstants.fetchEventDetailsV5;
    connect.post(url, body).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_SUCCESS,
          data: response.data,
          tracks: response.data.tracks,
        });
      } else if (response.status === 403) {
        const lockedBehaviour = config.lockedContentBehaviour;
        switch (lockedBehaviour) {
          case TenantLockedContentBehaviour.show_popup:
            navigate(Routes.accountPage);
            break;
          case TenantLockedContentBehaviour.show_subscription_options:
            navigate("/subscribe");
            break;
          case TenantLockedContentBehaviour.do_nothing:
            navigate(Routes.accountPage);
            //do nothing
            break;

          default:
            navigate(Routes.accountPage);
            break;
        }
        dispatch({
          type: actionTypes.FETCH_STREAMINGLINK_FAIL,
        });
      }
    });
  };
};

export const resetStreamingLink = (page) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_STREAMINGLINK_SUCCESS,
      data: page === "class_detail" ? undefined : "",
    });
  };
};

export const getRecordedEventData = (event_id) => {
  return (dispatch) => {
    let userId = localStorage.getItem("userId");
    let url = APIConstants.fetchEventDataV6;
    const body = {
      TenantId: config.name,
      EventId: event_id,
    };
    const connect = userId ? userApi : api;
    connect.post(url, body).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_RECORDEDEVENTDATA_SUCCESS,
          data: response.data,
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_RECORDEDEVENTDATA_FAIL,
        });
      }
    });
  };
};

export const clearVideoError = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.VIDEO_ERROR,
      error: null,
    });
  };
};

export const fetchIntensity = () => {
  return (dispatch) => {
    api.get(APIConstants.intensityAPI).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.FETCH_INTENSITY,
          intensities: response.data,
        });
      }
    });
  };
};

const getOnDemandPerformedLoc = (onDemandDetails) =>{
  const classPerformedLocPayload =  {
    classId:onDemandDetails?.contentID,
    className:onDemandDetails?.contentName,
    classDuration:ClassDurationTimeBand(onDemandDetails?.actualDurationSecond/60),
    playDuration:aroundTimeBand(onDemandDetails?.playedDurationSecond),
    classDurationSeconds:(onDemandDetails?.actualDurationSecond),
    playDurationSeconds:onDemandDetails?.playedDurationSecond,
    category: onDemandDetails?.classCategory,
    providerId:onDemandDetails?.providerId,
    providerName:onDemandDetails?.providerName,
    channelId:onDemandDetails?.channelId
  }
  return classPerformedLocPayload;
}
const getLiveSchedulePerformedLoc = (liveClassDetails) =>{
  let startDateObj = ""
  if (liveClassDetails?.selectedClass) {
    const selectedClass = liveClassDetails?.selectedClass
    startDateObj = selectedClass.nextOccurence + 'T' + selectedClass.activeTime
  }

  const liveClassPerformedLocPayload =  {
    classId:liveClassDetails?.contentID,
    live: true,
    className:liveClassDetails?.contentName,
    startDate: startDateObj, //new Date(), // event start date
    eventId:liveClassDetails?.contentID,
    playDuration:aroundTimeBand(liveClassDetails?.playedDurationSecond),
    playDurationSeconds:liveClassDetails?.playedDurationSecond,
    classDurationSeconds:(liveClassDetails?.actualDurationSecond * 60),
    classDuration: ClassDurationTimeBand(liveClassDetails?.actualDurationSecond),
    eventType: getEventTypeWord(liveClassDetails?.selectedClass?.eventType),
  }
  return liveClassPerformedLocPayload;
}

export const logOnDemandTracking = (
  selectedClass,
  userId,
  elapsedTime,
  isClubConnect
) => {
   
  if (selectedClass.id) {
    var body = {
      requestsource: 1,
      subscriptiontag: "",
      performDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      providerName:
        selectedClass.provider ??
        selectedClass?.provider_id ??
        selectedClass?.providerName,
      providerId:
        selectedClass.providerID ??
        selectedClass?.newProviderId ??
        selectedClass?.providerId,
      contentID: selectedClass.id,
      contentName: selectedClass.title,
      // actualDurationSecond: selectedClass.duration * 60,
      playedDurationSecond: elapsedTime,
      subscriptionStartDate: "",
      userId: userId,
      ID: userId + "-" + selectedClass.id + "-" + moment().unix(),
    };
    if (selectedClass?.duration || selectedClass?.actualDurationSecond) {
      body.actualDurationSecond =
        selectedClass?.durationSecond ?? selectedClass?.durationInSec ?? selectedClass?.actualDurationSecond;
    }
  } else {
    // eslint-disable-next-line no-redeclare
    var body = {
      requestsource: 1,
      subscriptiontag: "",
      performDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      providerName: selectedClass?.provider,
      providerId: selectedClass.providerID,
      contentID: selectedClass.tag,
      contentName: selectedClass.className,
      // actualDurationSecond: selectedClass.durationSecond,
      playedDurationSecond: elapsedTime,
      subscriptionStartDate: "",
      userId: userId,
      ID: userId + "-" + selectedClass.tag + "-" + moment().unix(),
    };
    if (selectedClass?.durationSecond) {
      body.actualDurationSecond = selectedClass.durationSecond;
    }
  }
  // if (isClubConnect) {
  //   body = { ...body, providerName: "Wexer Demo" }
  // }
  if (body.providerName === undefined) {
    body.providerName = config.name;
  }
  if (body.providerId === undefined) {
    body.providerId = config.providerId;
  }
  return (dispatch) => {
    if(selectedClass?.type){
      logLiveConnectClassPerformed(getLiveSchedulePerformedLoc({...body,selectedClass:selectedClass}))  
    }else{
      logODClassPerformed(getOnDemandPerformedLoc({...body,channelId: selectedClass?.channelId,classCategory: selectedClass?.classCategories ? selectedClass?.classCategories:selectedClass?.classCategory}))
    }
    if (body.playedDurationSecond > 0) {
      dispatch({
        type: actionTypes.CLASS_LOGGED,
        playedDurationSecond: body.playedDurationSecond,
      });

      api
        .post(APIConstants.logOnDemandTrackingAPI, body)
        .then((response) => {});
    }
  };
};

export const setClassFavourite = (
  selectedClassTag,
  userId,
  isSettingFavourite
) => {
  /*  dispatch({ type: actionTypes.IS_FAV, isFav: isSettingFavourite }); */
  if (isSettingFavourite) {
    const body = {
      userId: userId,
      virtualClassTag: selectedClassTag,
    };

    return (dispatch) => {
      userApi
        .post(APIConstants.setClassFavouritePost, body)
        .then((response) => {});
    };
  } else {
    let url = APIConstants.setClassFavouritePost;
    url = url + "/" + selectedClassTag;
    return (dispatch) => {
      userApi.delete(url).then((response) => {});
    };
  }
};

export const setClassFavouriteOnLocalColletion = (
  selectedCollection,
  classId,
  isSettingFavourite
) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_FAV_LOCAL,
      payload: {
        collectionName: selectedCollection,
        tag: classId,
        isSettingFavourite,
      },
    });
  };
};
//below one for deeplink class
export const setDeeplinkClassFavUnfavOnLocalColletion = (Collection) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_FAV_UNFAV_LOCAL,
      payload: [Collection],
    });
  };
};
//below one for deeplink collection
export const setDeeplinkCollectionFavUnfavOnLocal = (id) => {
  logger(id);
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_FAV_UNFAV_LOCAL_COLLECTION,
      index: id,
    });
  };
};
export const changeSkippedState = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_SKIPPED_STATE,
    });
  };
};

export const clearClassData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_CALSS_DATA,
    });
  };
};
export const clearLiveStreamRecordedData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_LIVESTREAM_RECORDED_DATA,
    });
  };
};
export const clearSearchData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_SEARCH_CALSS_DATA,
    });
  };
};
export const clearCategorySearchData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_CATEGORY_SEARCH_CALSS_DATA,
    });
  };
};
export const clearFavClassData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_FAV_CALSS_DATA,
      classes: null,
    });
  };
};
export const searchMetadata = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_METADATA_START,
    });
    let url = APIConstants.onDemandSearchMetadataAPI;
    let userId = localStorage.getItem("userId");
    let body = {
      tenantID: config.name,
    };

    // if (userId !== null) {
    //   url = url + "/" + userId;
    // } else {
    //   url = url + "/";
    // }

    api.post(url, body).then((response) => {
      dispatch({
        type: actionTypes.GET_SEARCH_METADATA,
        Metadata: response.data,

        /////////////////////////////////////////////////////////////
        //delete the changes
        //only for testing the single category
        //Nikhil Gupta
        // Metadata: {...response.data, categories : [response.data.categories[0]]}
      });
    });
  };
};

export const fetchChannelClasses = (data) => {
  const { from, searchTerm, keyword, providerId } = data
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CLASSESFORCHANNEL_START,
    });
      dispatch(
        searchOnDemandClass(
          from,
          searchTerm,
          keyword,
          false,
          1,
          0,
          maxInteger,
          true,
          "",
          "",
          "",
          "",
          "",
          "",
          "desc",
          "",
          "",
          false
          
        )
      );
    } 
};

export const searchOnDemandClass = (
  from,
  searchTerm,
  keyword,
  fromRecent,
  searchType,
  skip,
  take = 50,
  ShowMetaData = true,
  focusArea = "",
  skill = "",
  equipment = "",
  duration = "",
  intensity = "",
  searchText = "",
  dir = "desc",
  classLanguage = "",
  subcategory = "",
  fromSearch = false,
  providerId = "",
  mediaType = "",
  sort = "scheduledate"
) => {
  let body;
  let userId = localStorage.getItem("userId");
  if (userId === null) {
    userId = "";
  }
  if (fromRecent) {
    body = {
      virtualClasses: searchTerm,
    };
  } else if (!fromRecent && searchType === 1) {
    if (dir.length > 0) {
      if (dir === "Newest" || dir === "desc") dir = "desc";
      else dir = "asc";
    }
    body = {
      query: searchText,
      take: take,
      skip: skip ? skip : 0,
      categoryId: from === PAGE_CONSTS.category ? searchTerm : "",
      channelId: from === PAGE_CONSTS.channels ? searchTerm : "",
      labels: keyword.length > 0 ? keyword : "",
      type: "",
      // focusArea: focusArea.length > 0 ? focusArea : '',
      skill: skill.length > 0 ? skill : "",
      // equipment: equipment.length > 0 ? equipment : '',
      duration: duration,
      intensity: intensity.length > 0 ? intensity : "",
      dir: dir,
      classLanguageName: classLanguage,
      mediaType: mediaType,
      ClassSubCategoryId: subcategory,
      providerid: providerId !== "" ? providerId : "",
      sort: sort,
    };
    //Remove type and put categoryId
  } else if (!fromRecent && searchType === 0) {
    let presecond;
    if (searchTerm[0] !== "") {
      // compareDurationMins will pick string values from PO editor as Duration selector
      if (searchTerm[0] === compareDurationMins()["10mins"]) {
        presecond = "0,960";
      } else if (searchTerm[0] === compareDurationMins()["20mins"]) {
        presecond = "961,1559";
      } else if (searchTerm[0] === compareDurationMins()["30mins"]) {
        presecond = "1560,2159";
      } else if (searchTerm[0] === compareDurationMins()["40mins+"]) {
        presecond = "2160,18000";
      } else if (searchTerm[0] === "all") {
        presecond = "0,18000";
      }
    }
    body = {
      duration: presecond,
      type: searchTerm[1],
      provider: searchTerm[2],
      take: 50,
      skip: skip ? skip : 0,
    };
  } else if (searchType === undefined) {
    if (searchTerm) {
      body = { duration: "0,30800" };
    }
  }

  return (dispatch, getState) => {
    const { onDemand } = getState();
    const filterFocusArea = focusArea.length > 0 ? focusArea : "";
    if (!isEmpty(filterFocusArea)) {
      const getFocusArea = onDemand?.Metadata?.focusArea.filter(
        (item) => item.name === filterFocusArea
      );
      if (getFocusArea.length) {
        body.focusAreaTags = getFocusArea[0].tag;
      }
    }
    const filterEquipment = equipment.length > 0 ? equipment : "";
    if (!isEmpty(filterEquipment)) {
      const getEquipmentName = onDemand?.Metadata?.equipmentList.filter(
        (item) => item.name === filterEquipment
      );
      if (getEquipmentName.length) {
        body.equipmentTags = getEquipmentName[0].typeTag;
      }
    }
    if (!skip) {
      if (!fromSearch) {
        dispatch({
          type: actionTypes.SEARCH_ONDEMAND_START,
        });
      } else {
        if (searchText.length !== 0) {
          dispatch({
            type: actionTypes.FILTER_BY_SEARCH_TEXT_START,
          });
        }
        dispatch({
          type: actionTypes.FILTER_BY_SEARCH_START,
        });
      }
      if (searchType === 0) {
        dispatch({
          type: actionTypes.CATEGORY_PARAMS_SKIPPED,
          prevSearchParams: searchTerm,
        });
      }
    }

    if (providerId !== "" && !fromSearch) {
      dispatch({
        type: actionTypes.FETCH_ONDEMAND_START,
      });
    }
    const connect = userId == "" ? api : userApi;
    connect.setHeader("ShowMetaData", ShowMetaData);
    setHeaderLanguage(api);
    connect
      .post(APIConstants.onDemandNewSearchAPI , body)
      .then((response) => {
        if (fromRecent) {
          dispatch({
            type: actionTypes.SEARCH_ONDEMAND_CLASS_RECENT_SUCCESS,
            classes: response.data,
          });
        } else {
          if (skip) {
            if (searchType === 0) {
              dispatch({
                type: actionTypes.SKIPPED_CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS,
                classes: response.data,
                searchType: searchType,
              });
            } else {
              dispatch({
                type: actionTypes.SKIPPED_SEARCH_ONDEMAND_CLASS_SUCCESS,
                classes: response.data,
                searchTerm: searchTerm,
                searchType: searchType,
              });
            }
          } else {
            if (searchType === 0) {
              dispatch({
                type: actionTypes.CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS,
                classes: response.data,
                searchType: searchType,
              });
            } else {
              if (!fromSearch) {
                if (providerId !== "") {
                  if (from === PAGE_CONSTS.category){
                  dispatch({
                    type: actionTypes.FETCH_ONDEMAND_SUCCESS,
                    data: response.data,
                  });}
                  else if (from === PAGE_CONSTS.channels) {
                  dispatch({
                    type: actionTypes.FETCH_CLASSESFORCHANNEL_SUCCESS,
                    data: response.data,
                  });}
                } else {
                  if (from === PAGE_CONSTS.channels) {
                dispatch({
                  type: actionTypes.FETCH_CLASSESFORCHANNEL_SUCCESS,
                  data: response.data,
                });
                }
                  dispatch({
                    type: actionTypes.SEARCH_ONDEMAND_CLASS_SUCCESS,
                    classes: response.data,
                    searchTerm: searchTerm,
                    searchType: searchType,
                  });
                }
              } else {
                if (from === PAGE_CONSTS.channels) {
                  dispatch({
                    type: actionTypes.FILTER_CHANNEL_SUCCESS,
                    data: response.data,
                    searchTerm: searchTerm,
                    searchType: searchType,
                  });
                }
                dispatch({
                  type:
                    providerId !== ""
                      ? actionTypes.FLL_FILTER_ONDEMAND_SUCCESS
                      : actionTypes.FILTER_BY_SEARCH,
                  classes: response.data,
                  searchTerm: searchTerm,
                  searchType: searchType,
                });
              }
            }
          }
        }
      });
    // return Promise.resolve();
  };
};

export const getFavouritedClasses = (pageNo, pageSize) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_FAVOURITED_CLASSES });
    const body = {
      tenantId: config.name,
      PageNo: pageNo,
      PageSize: pageSize,
    };
    userApi.post(APIConstants.setClassFavouriteGet, body).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.SET_FAVOURITED_CLASSES,
          favoritedClasses: response.data,
        });
      } else dispatch({ type: actionTypes.SET_FAVOURITED_CLASSES });
    });
  };
};

export const storeSearchParams = (params) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.STORE_SEARCH_PARAMS, params: params });
  };
};
export const selectOnDemandClass = (sClass) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECT_ONDEMAND_CLASS,
      selectedClass: sClass,
    });
  };
};

export const updateBrowseClasses = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SEARCH_ONDEMAND_CLASS_SUCCESS,
      classes: data,
    });
  };
};

export const resetBrowseClassData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CLEAR_SEARCH_CALSS_DATA,
    });
  };
};
export const resetFllOndemand = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_FLL_ONDEMAND,
    });
  };
};
export const updateFllOnDemandData = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ONDEMAND_SUCCESS,
      data: data,
    });
  };
};

export const liveStreamStarted = (status) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LIVE_STREAM_STARTED,
      status,
    });
  };
};

export const DownloadDocument = (tenantId, documentId) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFY_USER,
      NotificationText: Translate({
        id: "ClassListViews.PreparingDownload",
      }),
    });
    var data = {
      tenantId: tenantId,
      documentId: documentId,
    };
    api.post(APIConstants.downloadDocument, data).then((response) => {
      if (response.ok) {
        dispatch({
          type: actionTypes.DOCUMENT_LIBRARY_DOWNLOAD,
          documentDownloadUrl: response.data.documentUrl,
        });
      } else {
        dispatch({
          type: actionTypes.NOTIFY_USER,
          NotificationText: Translate({
            id: "ApiResponseStrings.GenericError",
          }),
        });
      }
    });
  };
};
