import { useDispatch, useSelector } from "react-redux";
import { setKeywords } from '../store/actions/filter';


const useFilter = () => {
    const dispatch = useDispatch();

    const filterType = (channel) => {
        let filterInUse
        if (channel) filterInUse = "filterChannel";
        else if (channel === false) filterInUse = "filterGlobalSearch";
        else filterInUse = "filter"
        return filterInUse
    }

    const useFilterState = (props) => {
        const filterInUse = filterType(props?.showChannelFilter)
        return({
            selectedFilters: useSelector(state => state[filterInUse].selectedFilters),
            filteredKeywords: useSelector(state => state[filterInUse].filteredKeywords),
            filterSearchText: useSelector(state => state[filterInUse].filterSearchText),
            loading : useSelector(state => state.onDemand.searchLoading)
        })
    }
    const useFiltertDispatch = () => ({
        setKeywords: (keyword)=> {
            dispatch(setKeywords(keyword));
        },
    })
    return {useFilterState, useFiltertDispatch}
}
export {useFilter}