import * as actionTypes from './actionTypes'

export const setselectedFiltersGlobalSearch = (filterValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS_GLOBAL_SEARCH,
            value: filterValue
        });
    };
};

export const setKeywordsGlobalSearch = (keywordsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_KEYWORDS_GLOBAL_SEARCH,
            value: keywordsValue
        });
    };
};


export const setCategoryGlobalSearch = (categoryValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_CATEGORY_GLOBAL_SEARCH,
            value: categoryValue
        });
    };
};


export const setDurationGlobalSearch = (durationValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_DURATION_GLOBAL_SEARCH,
            value: durationValue
        });
    };
};

export const settempClassSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TEMP_CLASS_SEARCH_DATA,
            value: value
        });
    };
};

export const setCategoriesGlobalSearch = (categoryValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_CATEGORY_GLOBAL_SEARCH,
            value: categoryValue
        });
    };
};

export const setSubCategoriesGlobalSearch = (subCategoryValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_SUB_CATEGORY_GLOBAL_SEARCH,
            value: subCategoryValue
        });
    };
};


export const setSkillLevelGlobalSearch = (skillValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SKILL_GLOBAL_SEARCH,
            value: skillValue
        });
    };
};

export const setFocusAreaGlobalSearch = (focusAreaValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_FOCUS_AREA_GLOBAL_SEARCH,
            value: focusAreaValue
        });
    };
};

export const setEquipmentsTypesGlobalSearch = (equipmentsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_EQUIPMENTS_TYPES_GLOBAL_SEARCH,
            value: equipmentsValue
        });
    };
};

export const setIntensityLevelGlobalSearch = (intensityValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_INTENSITY_GLOBAL_SEARCH,
            value: intensityValue
        });
    };
};

export const setClassLanguageGlobalSearch = (languageValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASS_LANGUAGE_GLOBAL_SEARCH,
            value: languageValue
        });
    };
};

export const setSortGlobalSearch = (sortValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SORT_GLOBAL_SEARCH,
            value: sortValue
        });
    };
};

export const setFilterSearchTextGlobalSearch = (searchValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SEARCH_TEXT_GLOBAL_SEARCH,
            value: searchValue
        });
    };
};

export const setFilteredClassSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_CLASS_SEARCH_DATA_GLOBAL_SEARCH,
            value: value
        });
    };
};

export const setFilteredTopKeywordsGlobalSearch = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_TOP_KEYWORDS_GLOBAL_SEARCH,
            value: value
        });
    };
};
export const setPageCount = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGE_COUNT_GLOBAL_SEARCH,
            value: value
        });
    };
};

export const setPaginatedData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGINATED_DATA_GLOBAL_SEARCH,
            value: value
        });
    };
};

export const setClassesSearchDataGlobalSearch = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASSES_SEARCH_DATA_GLOBAL_SEARCH,
            value: value
        });
    };
};

export const settempClassSearchDataChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TEMP_CLASS_SEARCH_DATA_GLOBAL_SEARCH,
            value: value
        });
    };
};

export const setTopKeywordsGlobalSearch = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TOP_KEYWORDS_GLOBAL_SEARCH,
            value: value
        });
    };
};

export const resetFiltersGlobalSearch = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_FILTERS_GLOBAL_SEARCH,
        });
    };
};

export const setGlobalSearchMediaType = (mediaType) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_MEDIA_TYPE_GLOBAL_SEARCH,
            value: mediaType
        });
    };
};