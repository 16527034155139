import * as actionTypes from "./actionTypes";
import api from "../../api";
import * as APIConstants from "../APIConstants";
import userApi from "userAPI";

export const setGlobalSearch = (query, pageFrom) => (dispatch) => {
  dispatch({
    type: actionTypes.GLOBAL_SEARCH_SET_QUERY,
    payload: { query, pageFrom },
  });
};
export const handelSearchBox =
  (showSearch = false) =>
  (dispatch) => {
    dispatch({
      type: actionTypes.GLOBAL_SEARCH_SHOW,
      payload: { showSearch },
    });
  };

export const getGlobalSearch = (userId, searchText) => {
  return (dispatch) => {
    // const {globalSearch: { loading,query } } = getState();

    dispatch({
      type: actionTypes.GLOBAL_SEARCH_START,
      payload: { loading: true, msg: "", globalSearchData: [] },
    });
    const connect = userId ? userApi : api;
    connect.setHeader("ShowMetaData", true);

    connect
      .post(APIConstants.onDemandNewSearchAPI, {
        query: searchText,
        take: 9999,
      })
      .then((response) => {
        // dispatch({
        //     type: actionTypes.GLOBAL_SEARCH_SUCCESS,
        //     payload:{error: false,msg:'fetch successfully'}
        // });
        dispatch({
          type: actionTypes.GLOBAL_SEARCH_SET_RESULT,
          payload: {
            error: false,
            msg: "fetch successfully",
            globalSearchData: response.data,
          },
        });
        dispatch({
          type: actionTypes.FILTER_GLOBAL_SEARCH_SUCCESS,
          data: response.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.GLOBAL_SEARCH_ERROR,
          payload: { error: true, msg: "something went wrong" },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.GLOBAL_SEARCH_END,
          payload: { loading: false },
        });
      });
  };
};
