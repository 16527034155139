/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from "react";
import { Translate } from "../utils/Translate";
import { Avatar, Grid, Typography } from "@material-ui/core";
import AccountButtonDotsMobile from "./AccountButtonDotsMobile";
import Routes from "../components/Router/Routes";
import { useLocation, useNavigate } from "react-router";
import { SearchItems } from "./SearchItems";
import IconSearch from "CommonImages/icon-search";
import { useSelector } from "react-redux";
import { GLOBALSEARCH_FOCUS_TIMEOUT } from "utils/constants";
const config = require("../assets/config.json");

const LoggedInTexts = (props) => {
  const history = useNavigate();
  
  const entitlement = useSelector(state => state.tenantConfig.entitlement?.headerLoggedIn)
  const featuresConfig = useSelector(state => state.tenantConfig.entitlementResponse[0]?.features)
  const userEntitlement = useSelector(state => state.tenantConfig.entitlement?.account)
  const location = useLocation();
  const findString = "/search/";
  const checkOnSearchPage = () => {
    return window.location.pathname.includes(findString);
  };
  const getStateData = () => {
    const showSearch = checkOnSearchPage();
    let [, searchText] = showSearch
      ? window.location.pathname.split(findString)
      : "";
    if (searchText) searchText = decodeURI(searchText);
    return { showSearch, searchText };
  };

  const handelSearch = () => {
    if (
      window.location.href.includes("classes/search") ||
      !window.location.href.includes("search")
    ) {
      props.handelSearchBox(true);
    }
  };

  const closeSearch = () => {
    props.handelSearchBox(false);
  };
  const handelShowResultPage = () => {
    const inputEle = document.querySelector(".text-box input");
    if (inputEle?.value?.length) {
      const query = encodeURI(inputEle?.value ?? "");
      const isSearchPage = checkOnSearchPage();
      props.setSearchQuery(
        query,
        isSearchPage ? props.pageFrom : window.location.pathname
      );
      history(`/search/${query}`);
      props.handelSearchBox(false);
    }
  };
  const handleClickToFocus = () => {
    const inputEle = document.querySelector(".text-box input");
      setTimeout(() => {
        inputEle?.focus();
      }, GLOBALSEARCH_FOCUS_TIMEOUT); 
  };
  const goToUserSection = () => {
    switch (true) {
      case featuresConfig?.subscription && userEntitlement.mysubscription:
        history(Routes.mySubscriptionsRoute);
        break;
      case userEntitlement.favourite:
        history(Routes.favClassRoute);
        break;
      case userEntitlement.recentactivity:
        history(Routes.recentActivityRoute);
        break;
      case featuresConfig?.ratingEnabled && userEntitlement.rating:
        history(Routes.reviewsRoute);
        break;
      case userEntitlement.playlist:
        history(Routes.userPlayList);
        break;
    
      default:
        history(Routes.accountPage);
        break;
    }
   
  }
  return (
    <React.Fragment>
      <div className="loginButtonHolder" >
        <Grid container justifyContent="flex-end" alignItems="center">
          {props.showSearch === false && (
            <>
              {(entitlement?.live || entitlement?.ondemand) && (
                <Grid item  > 
                  <a data-testid={"liveOndemand-id"}
                    className={
                      location.pathname === Routes.connectClasses ||
                      location.pathname.indexOf("live") > -1 ||
                      location.pathname.indexOf("connect") > -1
                        ? `headerLinks-fitness ${config.name} dynamiclinks`
                        : "headerLinks-fitness"
                        
                    }
                    onClick={() => {
                      entitlement.live
                        ? history(Routes.connectLiveSchedule)
                        : history(Routes.connectOndemandClasses);
                    }} role='button'
                    tabIndex='0'
                    aria-label='Live or Ondemand class page'
                  >
                    <Typography variant="button" role='button'  tabIndex='0' aria-label='Live or Ondemand class page'>
                      {Translate({ id: "navigation.ConnectTopNav" })}
                    </Typography>
                  </a>
                </Grid>
              )}
              {entitlement?.category ? (
                <Grid item>
                  <a data-testid={"category-id"}
                    className={
                      location.pathname === Routes.classListViewRoute ||
                      location.pathname === Routes.searchRoute ||
                      window.location.href.indexOf("categories") > -1
                        ? `headerLinks-fitness ${config.name} dynamiclinks`
                        : "headerLinks-fitness"
                    }
                    onClick={() => {
                      history(Routes.classListViewRoute);
                    }} role='button'
                    aria-label='Class page'
                    tabIndex='0'
                  >
                    <Typography variant="button" role='button'  tabIndex='0' aria-label='Class page'>
                      {Translate({ id: "navigation.ClassesTopNav" })}
                    </Typography>
                  </a>
                </Grid>
              ) : null}
              {entitlement?.channel ? (
                <Grid item>
                  <a data-testid={"channel-id"}
                    className={
                      location.pathname === "/channels" ||
                      window.location.href.indexOf("channels") > -1
                        ? `headerLinks-fitness ${config.name} dynamiclinks`
                        : "headerLinks-fitness"
                    }
                    onClick={() => {
                      history("/channels");
                    }} role='button'
                    aria-label='Channels page'
                    tabIndex='0'
                  >
                    <Typography variant="button" role='button'  aria-label='Channels page' tabIndex='0'>
                      {Translate({ id: "navigation.ChannelsTopNav" })}
                    </Typography>
                  </a>
                </Grid>
              ) : null}
              {entitlement?.collection ? <Grid item>
                <a data-testid={"collection-id"}
                  className={
                    location.pathname === Routes.collections ||
                    window.location.href.indexOf("collection") > -1
                      ? `headerLinks-fitness ${config.name} dynamiclinks`
                      : "headerLinks-fitness"
                  }
                  style={{
                    marginRight: "10px",
                  }}
                  aria-label='Collections page'
                  onClick={() => {
                    history(Routes.collections);
                  }} role='button'
                  tabIndex='0'
                >
                  <Typography variant="button" role='button' aria-label='Collections page' tabIndex='0'>
                    {Translate({ id: "navigation.CollectionsTopNav" })}
                  </Typography>
                </a>
              </Grid> : null}
              {featuresConfig?.subscription && entitlement?.subscription ? <Grid item>
                <a
                data-testid={"subscription-id"}
                  className={
                    location.pathname === "/subscription"
                      ? `headerLinks-fitness dynamiclinks`
                      : "headerLinks-fitness"
                  }
                  style={{
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    history(Routes.subscriptionList);
                  }} role='button'
                  aria-label='Subscription page'
                  tabIndex='0'
                >
                  <Typography variant="button" role='button' aria-label='Subscription page' tabIndex='0'>
                    {Translate({ id: "navigation.Subscription" , defaultMessage: "Subscriptions"})}
                  </Typography>
                </a>
              </Grid> : null}
              {entitlement.search ? (window.location.href.includes("classes/search") || !window.location.href.includes("search")) && (
                <Grid item id="search-icon" className="item-search">
                  <div className="icon-search" onClick={handelSearch} role='button' aria-label='Search' tabIndex='0'>
                    <IconSearch />
                  </div>
                </Grid>
              ) : null}
              {entitlement.search ? (window.location.href.includes("search") &&
                !window.location.href.includes("classes/search") && (
                  <Grid item className="item-search">
                    <div
                      className="icon-search dynamicLink"
                      onClick={handleClickToFocus}
                      role='button'
                      aria-label='Search'
                      tabIndex='0'
                    >
                      <IconSearch color={config.primaryColor} />
                    </div>
                  </Grid>
                )) : null}
              <Grid item>
                <Avatar
                  style={{ cursor: "pointer" }}
                  alt="Travis Howard"
                  // src={avatarImg}
                  onClick={() => {
                    goToUserSection()
                  }}
                  role='button'
                  aria-label='Search'
                  tabIndex='0'
                />
              </Grid>
            </>
          )}
          {props.showSearch === true &&
            (window.location.href.includes("classes/search") ||
              !window.location.href.includes("search")) && (
              <Grid item id="search-box" className="item-search">
                <SearchItems
                  focus
                  handelBack={handelShowResultPage}
                  closeSearch={closeSearch}
                  loading={props.globalSearchLoading}
                  showSearch={props.showSearch}
                  crossIconId={"crossIconId"}
                />
              </Grid>
            )}
        </Grid>
      </div>

      <div className="loginButtonHolderMobile pull-right">
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Avatar
              alt="Travis Howard"
              onClick={() => {
                goToUserSection();
              }}
              role='button'
              aria-label='Menu'
              tabIndex='0'
            />
          </Grid>
          {/* <AccountButtonDotsMobile></AccountButtonDotsMobile> */}
          <AccountButtonDotsMobile
            searchText={getStateData().searchText}
            handelBack={handelShowResultPage}
            loading={props.globalSearchLoading}
            isSearchPage={checkOnSearchPage()}
            entitlement={entitlement}
            featuresConfig={featuresConfig}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default LoggedInTexts;
