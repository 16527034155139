import * as actionTypes from "../actions/actionTypes";

const initialState = {
    globalSearchData: [],
    loading: false,
    error:false,
    msg:'',
    query:'',
    pageFrom:'',
    showSearch:false,
    classLoadingFailed: false,
    SearchType:0,
    SearchTerm:"",
    channelSearchLoading: true,
    tempFilterSearchClasses:[],
    metaData:[]
};


const fetchClassesForChannelSuccess = (state, action) => {
    return updateObject(state, {
        classes: action.classes,    
        metaData: action.data.metaData,
        classes: action.data.items,    
        isClassesLoading: false,  
        classLoadingFailed: false
    });
}

const channelSearchByFilterSuccess = (state, action) => {
    return updateObject(state, {
      tempFilterSearchClasses: action.data.items,
      channelSearchLoading: false,
      SearchTerm: action.searchTerm,
      SearchType: action.searchType,
    });
  };


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GLOBAL_SEARCH_START:
        case actionTypes.GLOBAL_SEARCH_SET_QUERY:
        case actionTypes.GLOBAL_SEARCH_END:
        case actionTypes.GLOBAL_SEARCH_ERROR:
        case actionTypes.GLOBAL_SEARCH_SUCCESS:
        case actionTypes.GLOBAL_SEARCH_SET_RESULT:
        case actionTypes.GLOBAL_SEARCH_SHOW:
            return {...state,...action.payload};
        case actionTypes.FILTER_GLOBAL_SEARCH_SUCCESS:
                return fetchClassesForChannelSuccess(state, action);

        default:
            return state;
    }
};
export default reducer;
