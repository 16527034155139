import React, { useState,useEffect } from 'react'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types'
import { Translate } from '../../utils/Translate';
import { getRecentSearches, getTranslatedFile } from '../../utils/utilityfunctions';
import { MAX_RECENT_SEARCHES, MINIMUM_GLOBAL_SEARCH } from '../../utils/constants';
import { useSelector } from 'react-redux';
import config from "../../assets/config.json";
import SearchSuggestions from './SearchSuggestions';
const SearchItems = props => {
    const showSearch = useSelector(state=> state.globalSearch?.showSearch)
    const {closeSearch,handelBack,searchText,loading,noCrossIcon,textBox,showError,crossIconId} = props;
    const translatedFile = getTranslatedFile()
    const [isError, setError] = useState(showError)
    const [showDropdown, setShowDropdown] = useState(false)

    const isValid = ()=>{
       const searchEle = document.querySelector(textBox);
       return searchEle?.value?.length > MINIMUM_GLOBAL_SEARCH;
    }
    const handelSearch = (e)=> {if(e.key === "Enter")  handelSubmit() };
    const handleKeyDown = (e) => e.stopPropagation()
    const handelSubmit = ()=>{
        if(isValid()){
            const searchEle = document.querySelector(textBox);
            const searchTextValue = searchEle?.value;
            isError && setError(false);
            handelBack();
            addToRecentSearch(searchTextValue)
        }
        else{
            !isError && setError(true);
        }
        handleHideRecentSearch()
    }
    useEffect(()=>{
         if(showSearch){
            const inputEle = document.querySelector("#search-box");
            const cross = document.querySelector("#crossIconId");
            inputEle.addEventListener('click', event => {
                event.stopPropagation()
            })
            cross.addEventListener('click', event => {
                closeSearch()
            })
         }
    },[showSearch])

    const handleHideRecentSearch = ()=>{
        setTimeout(() => {
            setShowDropdown(false)
        }, 250);
    }

    const addToRecentSearch = (searchText)=>{
        if(searchText.length < MINIMUM_GLOBAL_SEARCH) return;
        let recentSearches = getRecentSearches()
        if(recentSearches.includes(searchText)) {
            recentSearches = recentSearches.filter(item => item !== searchText)
        };
        recentSearches.unshift(searchText)
        recentSearches = recentSearches.slice(0,MAX_RECENT_SEARCHES)
        localStorage.setItem("recentSearches",JSON.stringify(recentSearches))
    }

    const handleShowRecentSearch = ()=>{
        const recentSearches = getRecentSearches();
        recentSearches.length > 0 ? setShowDropdown(true) : null;
    }

  return (
    <div className='search-items'>
            <div className={`${showDropdown ? "alt-border-radius": ""}  comp-search ${loading ? 'disable' : ''}`}>
                    <div className='icon-search' role='button' tabindex='0' aria-label="search"><SearchIcon onClickCapture={handelSubmit} height={24} width={24} /></div>
                    <div className='text-box'>
                            <InputBase
                                id='search-box-input' 
                                autoComplete='off'
                                readOnly={loading} 
                                defaultValue={searchText} 
                                onKeyUp={handelSearch} 
                                onKeyDown={handleKeyDown}
                                placeholder={translatedFile?.ClassListViews?.SearchClasses
                                    ? translatedFile?.ClassListViews?.SearchClasses
                                    : "Search Classes"}
                                onFocus={handleShowRecentSearch}
                                onBlur={handleHideRecentSearch}
                            />
                    </div>
                    {!noCrossIcon && <div className='icon-close' id={crossIconId} role='button' tabindex='0' aria-label="close"><Close onClick={closeSearch} height={24} width={24} color={config.primaryColor}/></div>}
            </div>        
            {showDropdown && <SearchSuggestions  handleSubmit={handelSubmit} closeRecentSearch={handleHideRecentSearch} /> }
            {isError && <div className='error-msg'>{Translate({ id: "ClassListViews.SearchMinCharMessage"})}</div>}
    </div>
)
}
SearchItems.defaultProps = {
    closeSearch : ()=>{},
    handelBack : ()=>{},
    searchText : '',
    loading : false,
    noCrossIcon : false,
    focus : false,
    showError : false,
    textBox : ".text-box input",
}

SearchItems.propTypes = {
    closeSearch : PropTypes.func,
    handelBack : PropTypes.func,
    searchText : PropTypes.string,
    textBox : PropTypes.string,
    loading : PropTypes.bool,
    noCrossIcon : PropTypes.bool,
    showError : PropTypes.bool,
    focus : PropTypes.bool,
}

export { SearchItems}