import React from "react"

function ClearSearch(){
    const color = (require("../assets/config.json")).primaryColor?.slice(1)

    const src = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
        <circle cx="10" cy="10" r="10" fill="%23${color}" fillRule="nonzero"/>
        <g stroke="%23ffffff" stroke-linecap="square" stroke-width="2">
            <path d="M13.668 13.668L6.29 6.29M6.29 13.668l7.378-7.378"/>
        </g>
    </g>
</svg>`

    return (
    <img alt="clear-search" src={`data:image/svg+xml;utf8,${src}`} />
    )
}

export default ClearSearch
