import { Translate } from "utils/Translate";
import { EventTypeFullName, EventTypes, LocalStorageKeys, platformSource,SUBSCRIPTION_SOURCE,TenantLockedContentBehaviour } from "./constants";
import Routes from "components/Router/Routes";
import { useSelector } from "react-redux";
import {store} from '../store/index'

const config = require("../assets/config.json");

export function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const defaultLanguageCode = "en-gb"

const extractLanguageCode = () => {
  const URL =
      window.location.search && window.location.search !== ""
        ? window.location.search
        : sessionStorage.getItem("embededConfig");

    if (URL && URL !== "" && URL.includes("runtime=embedded")) {
      const params = new Map(
        URL.slice(1)
          .split("&")
          .map((kv) => kv.split("="))
      );
      if (params.has("lang")) {
        config["language"] = params.get("lang");
      }
      // eslint-disable-next-line no-self-assign
      else config["language"] = config.language;
    }
    
    const languageCode = localStorage.getItem("language") ?? config.language;
    const languageCodeLowerCase = languageCode?.toLowerCase();
    return languageCodeLowerCase
}

export const getTranslatedFile = () => {
  console.log("TF: getTranslatedFile starts with code", extractLanguageCode());
  
  const state = store.getState()
  const languageData = state.onDemand.languageData
  try {
    console.log("TF: getTranslatedFile starts")
    let languageCode = extractLanguageCode();
    console.log("TF: getTranslatedFile languageCode ", languageCode)

    const translations = languageData || [];

    console.log("TF: getTranslatedFile translations ", translations)

    // Try to find a matching translation in Local Storage or JSON files
    let translation = findTranslation(translations, languageCode);
    if (!translation) {
      console.log("TF: getTranslatedFile No exact or partial match found, fall back to en-gb or any default")
      translation = getJSONFileWithName(defaultLanguageCode);
    }

    console.log("TF: getTranslatedFile found", translation)
    return translation;
  } catch (error) {
    console.error("Error in getTranslatedFile: ", error);
  }
};

const findTranslation = (translations, languageCode) => {  
  const translation = translations.find(t => t.langCode?.toLowerCase() === languageCode);
  if (translation) {
    return translation.terms;
  }
  
  const partialMatchTranslation = translations.find(t => isMatchLangCodes(t.langCode?.toLowerCase(), languageCode));
  if (partialMatchTranslation) {
    return partialMatchTranslation.terms;
  }

  console.log("TF: findTranslation If no match found in Local Storage, try JSON files (exact, then partial)")
  return getJSONFileWithPartialName(languageCode);
};

const getJSONFileWithPartialName = (languageCode) => {
  console.log("TF: getJSONFileWithPartialName starts ", languageCode)
  try {
    return require(`../translations/${languageCode?.toLowerCase()}.json`);    
  } catch (error) {
    console.log("TF: getJSONFileWithPartialName error ", error)
    // If exact match fails, attempt to find a partial match among supported languages
    let supportedLanguages = config?.supportedLanguages && config?.supportedLanguages.map(lang => lang?.toLowerCase());
    let partialLangCode = supportedLanguages?.find(lang => isMatchLangCodes(lang, languageCode));
    if (partialLangCode) {
      return getJSONFileWithName(partialLangCode);
    }
  }

  // Return null if no matches found
  return null;
};

const getJSONFileWithName = (name) => {
  console.log("TF: getJSONFileWithName starts ", name)
  const langCode = name?.toLowerCase();
  let content = null;

  const fallbacks = ['en-gb', 'en', 'en-us'];

  try {
    content = require(`../translations/${langCode}.json`);    
  } catch (primaryError) {    
    for (let i = 0; i < fallbacks.length; i++) {
      try {
        content = require(`../translations/${fallbacks[i]}.json`);
        break; // Break the loop if a file is successfully loaded
      } catch (fallbackError) {
        if (i === fallbacks.length - 1) {
          console.error("No suitable fallback language file found.");
        }
      }
    }
  }
  console.log("TF: getJSONFileWithName content", content)
  return content;
};

const isMatchLangCodes = (var1, var2) => {
  return var1.startsWith(var2) || var2.startsWith(var1);
}

export const getDurationMins = () => {
  const language = getTranslatedFile();
  const durationMins = [
    { value: language.ClassListViews["10mins"] },
    { value: language.ClassListViews["20mins"] },
    { value: language.ClassListViews["30mins"] },
    { value: language.ClassListViews["plusmins"] },
  ];
  return durationMins;
};

export const getDurationInMinsWithLabel = () => {
  const language = getTranslatedFile();
  const durationMins = [
    {
      value: "0,600",
      label: language?.ClassListViews["10mins"],
    },
    {
      value: "601,1200",
      label: language?.ClassListViews["20mins"],
    },
    {
      value: "1201,1800",
      label: language?.ClassListViews["30mins"],
    },
    {
      value: "1801,18000",
      label: language?.ClassListViews["plusmins"],
    },
  ];
  return durationMins;
};

export const SortValues = [
  { label: Translate({ id: "filter.Newest" }), value: "Newest" },
  { label: Translate({ id: "filter.Oldest" }), value: "Oldest" },
];

//compareDurationMins will compare string duration values
export const compareDurationMins = () => {
  const language = getTranslatedFile();
  return {
    "10mins": language.ClassListViews["10mins"],
    "20mins": language.ClassListViews["20mins"],
    "30mins": language.ClassListViews["30mins"],
    "40mins+": language.ClassListViews["plusmins"],
  };
};

//compareDurationMins will compare string duration values
export const createStateObj = (data) => {
  let obj = {};

  if (data && data.length > 0) {
    for (let i = data.length; i--; ) {
      obj[data[i]["formItems"]["name"]] = "";
    }
  }
  return obj;
};
export const isEmbeddedMode = () => !!sessionStorage.getItem("embededConfig");
export const isEmbedded = () => {
  if (config.embeddedConfig && config.embeddedConfig.isEmbedded === true) {
    return true;
  }
  return false;
};

export const isSeamlessMode = () => {
  if (isEmbedded()) {
    const embeddedConfig = config.embeddedConfig;
    // eslint-disable-next-line eqeqeq
    return (
      embeddedConfig.showSignInPage == false &&
      embeddedConfig.showSignUpPage == false
    );
  }
  return false;
};

export const liveEventStatusForWexer = (curr) => {
  // type feild for live events
  const isWexer = config.connectSource === "wexer";
  return isWexer && curr?.type === "true-live" && curr?.isStreaming === true
    ? true
    : false;
  // new Date(activeTime).getTime() <= today.getTime()
};

export const setHeaderLanguage = (api) => {
  try {
    const selectedLanguage = localStorage.getItem("language");
    if (selectedLanguage) api.setHeader("Accept-Language", selectedLanguage);
    else api.setHeader("Accept-Language", null);
  } catch (e) {
    api.setHeader("Accept-Language", null);
  }
};

export const checkSubscriptionForEmbeddedOnly = () => {
  if (isEmbeddedMode()) {
    return config.isSubscriptionApiCallSkip === true ? false : true;
  } else {
    return true;
  }
};

export const qsStrigify = (obj) => {
  try {
    return new URLSearchParams(obj).toString();
  } catch (error) {
    let res = "";
    Object.keys(obj).forEach((key, val) => (res += `${key}=${val}`));
    return res;
  }
};

export const getPosterSize = (cardType) => {
  const screenWidth = window.innerWidth;
  const breakPoints = [
    { cotdWidth: 500, channelBanner: 414 , playlistThumbnail:115,playlistPlayerThumbnail:115, logoWidth: 420},
    { cotdWidth: 785, classdetailCard: 832, channelBanner: 1359, playlistThumbnail: 147, playlistPlayerThumbnail: 147, logoWidth: 840 },
    {
      cotdWidth: 785,
      livecard: 500,
      classdetailCard: 1166,
      channelBanner: 1999,
      playlistThumbnail:192,
      playlistPlayerThumbnail:147,
      logoWidth: 1260,
    },
    {
      cotdWidth: 785,
      livecard: 500,
      classdetailCard: 1493,
      channelBanner: 2560,
      playlistThumbnail:192,
      playlistPlayerThumbnail:192,
      logoWidth: 1260
    },
  ];

  let cardWidth, playerWidth, channelBanner,playlistThumbnail,playlistPlayerThumbnail, logoWidth;

  if (screenWidth < 768) {
    cardWidth = breakPoints[0].cotdWidth;
    playerWidth = screenWidth;
    channelBanner = screenWidth;
    playlistThumbnail = breakPoints[0].playlistThumbnail;
    playlistPlayerThumbnail = breakPoints[0].playlistPlayerThumbnail;
    logoWidth = breakPoints[0].logoWidth;
  } else if (screenWidth <= 1360 && screenWidth > 767) {
    cardWidth = breakPoints[1].cotdWidth;
    playerWidth = breakPoints[1].classdetailCard;
    channelBanner = breakPoints[1].channelBanner;
    playlistThumbnail = breakPoints[1].playlistThumbnail;
    playlistPlayerThumbnail = breakPoints[1].playlistPlayerThumbnail;
    logoWidth = breakPoints[1].logoWidth;
  } else if (screenWidth <= 1999 && screenWidth > 1359) {
    if (cardType === "cotd-card") {
      cardWidth = breakPoints[2].cotdWidth;
    } else {
      cardWidth = breakPoints[2].livecard;
      playerWidth = breakPoints[2].classdetailCard;
      channelBanner = breakPoints[2].channelBanner;
      playlistThumbnail = breakPoints[2].playlistThumbnail;
      playlistPlayerThumbnail = breakPoints[2].playlistPlayerThumbnail;
      logoWidth = breakPoints[2].logoWidth;
    }
  } else if (screenWidth >= 2000) {
    if (cardType === "cotd-card") {
      cardWidth = breakPoints[3].cotdWidth;
    } else {
      cardWidth = breakPoints[3].livecard;
      playerWidth = breakPoints[3].classdetailCard;
      channelBanner = breakPoints[3].channelBanner;
      playlistThumbnail = breakPoints[3].playlistThumbnail;
      playlistPlayerThumbnail = breakPoints[3].playlistPlayerThumbnail;
      logoWidth = breakPoints[3].logoWidth;
    }
  }
  return { cardWidth, playerWidth, channelBanner,playlistThumbnail,playlistPlayerThumbnail, logoWidth };
};
export const getTime = (seconds) => {
  let minutes = ~~(seconds / 60);
  let extraSeconds = seconds % 60;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  extraSeconds = extraSeconds < 10 ? `0${extraSeconds}` : extraSeconds;
  return minutes + ":" + extraSeconds;
};
export const timeFormat = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
};

export const isEmptyOrNullString = (str) => {
  if (str === null || str === undefined || str.trim() === "") {
    return true;
  } else {
    return false;
  }
};

export const videoPlayerState = () => {
  let state = {
    elapsedTime: 0,
    videoPlaying: false,
    loading: true,
    video: {
      url: "",
      alternateLink: "",
      averageRating: "2",
      averageRatingCount: 1,
      calorieBurn: 0,
      channelId: "3b41bebb-5360-4c4e-bc18-e7697f900c9b",
      classCategories: "Dance",
      classCategory: ["Dance"],
      classCategoryId: 1004,
      classDescription: "Provider Avinash 07",
      className: "Provider Avinash 07",
      creationDate: "2022-01-06T12:21:46.3375314",
      displayDate: "2023-04-03T08:22:21.1602428Z",
      durationMinutes: "00:00:43",
      durationSecond: 43,
      equipmentNames: ["Suspension Trainer", "Mat"],
      equipmentTypes: false,
      favourite: false,
      focusArea: ["chest"],
      imageLink: "",
      instructor: "New instructor",
      intensity: 2,
      isActive: true,
      isTaPContent: false,
      keywords: null,
      languageName: "English",
      languageTag: "en",
      lastModifiedDate: "2023-03-07T04:25:13.5740523",
      level: "Beginner",
      provider: "P100120211",
      providerID: "Bh3RjYQMJNeHZVrgEzJFMZjVnYw2",
      scheduleDate: "2022-01-06T00:00:00",
      skill: 1,
      tag: "c76baf4e-b3ac-43da-9da8-50d2d88f19ca",
      trailerLinkMobile: null,
      trailerLinkWeb: null,
      trailerName: null,
    },
    finished: false,
    showCountdown: false,
    VideoError: false,
    classId: "",
    showDialog: false,
    isClubConnect: false,
    isLiveStream: false,
    isDeepLinkLoading: false,
    isThisClassDeepLink: false,
    eventId: 0,
    equipmentNames: null,
    fromDeepLink: true,
    showPlayList: false,
    thumbnail: "",
    url: "",
  };
  return state;
};

export const getSearchText = () => {
  try {
    const [, searchText] = window.location.pathname.split("/search/");
    return searchText ? decodeURI(searchText) : "";
  } catch (error) {
    return "";
  }
};

export const openHrefInNewTab = (description) => {
  var container = document.createElement("div");
  container.innerHTML = description;
  var anchors = container.getElementsByTagName("a");
  for (var i = 0; i < anchors.length; i++) {
    anchors[i].setAttribute("target", "_blank");
  }
  return container;
};

export const getImage = (img, extension) => {
  try {
    let src = require(`../assets/images/${img}.${extension}`);
    return src;
  } catch {
    let src = require(`../CommonImages/${img}.${extension}`);
    return src;
  }
};

export const getMediaScreenResolution = () => {
  return screen.width > 450;
};
export const getMediaScreenResolutionforMobile = () => {
  return screen.width <= 767;
};

export const passwordValidation = (value) => {
  if (value.length > 0) {
    var strongRegex = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$");
    if (strongRegex.test(value)) {
      return true;
    }
    return false;
  } else {
    return true;
  }
};

export const isPseudoSso = () => {
  const subscriptionSourceType = config.subscriptionSource?.find((subscriptionSource) => subscriptionSource?.toLowerCase().includes(SUBSCRIPTION_SOURCE.PSUEDO_SSO));
  return subscriptionSourceType ? true : false
}

export const getSubscriptionPlatformSource = (platformsource) => {
  const platformSourceType = platformsource?.toLowerCase();

  const platformSourceConstant = platformSource;

  switch (platformSourceType) {
    case "inapp":
      return platformSourceConstant.inapp;

    case "stripe":
      return platformSourceConstant.stripe;

    default:
      return platformSourceConstant.others;
  }
};

export const isNonEmptyArrayOrObject = (data) => {
  if (data !== null && data !== undefined) {
    if (Array.isArray(data)) {
      return data.length > 0;
    } else if (typeof data === "object") {
      return Object.keys(data).length > 0;
    }
  }

  return false;
};
export const checkValueNotNullNotUndefined = (data) => {
  if (data !== null || data !== undefined) {
    return true;
  } else {
    return false;
  }
};

export const appendQueryParameterInURL = (url,appendKey,appendValue) => {
  if (url !== "") {
    let urlAppend = new URL(url);
    urlAppend.searchParams.append(appendKey, appendValue);
    return urlAppend.href;
  }
  return;
}

export const handleClassClick = (tag,isUnlocked,navigate) => {
  if (!isUnlocked) {
    if (
      config.lockedContentBehaviour !== null && config.lockedContentBehaviour?.toLowerCase() ===
      TenantLockedContentBehaviour.do_nothing
    ) {
      return null;
    } else {
      //  take user to purchase subscription
    }
  } else if (
    isUnlocked !== false &&
    config.lockedContentBehaviour !== null &&
    config.lockedContentBehaviour?.toLowerCase() ===
      TenantLockedContentBehaviour.show_popup
  ) {
    navigate(Routes.accountPage);
  } else {
    navigate(Routes.onDemandClassPlayerRoute + tag);
  };
}

export const getDeviceName = () => {
  let device = "Unknown";
  const ua = {
    "Generic Linux": /Linux/i,
    Android: /Android/i,
    BlackBerry: /BlackBerry/i,
    Bluebird: /EF500/i,
    "Chrome OS": /CrOS/i,
    Datalogic: /DL-AXIS/i,
    Honeywell: /CT50/i,
    iPad: /iPad/i,
    iPhone: /iPhone/i,
    iPod: /iPod/i,
    macOS: /Macintosh/i,
    Windows: /IEMobile|Windows/i,
    Zebra: /TC70|TC55/i,
  };
  Object.keys(ua).map((v) => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
};
export const getOsName = () => {
   var uA = navigator.userAgent || navigator.vendor || window.opera;
   if (
     (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
     (uA.includes("Mac") && "ontouchend" in document)
   )
     return "iOS";

   var i,
     os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
   for (i = 0; i < os.length; i++)
     if (new RegExp(os[i], "i").test(uA)) return os[i];
};

export const isInternetConnected = () => {
  return navigator.onLine;
};
export const getTimeZoneRegionToCountry = () => {
  var  timeZoneCityToCountry = {
    Andorra: "Andorra",
    Dubai: "United Arab Emirates",
    Kabul: "Afghanistan",
    Tirane: "Albania",
    Yerevan: "Armenia",
    Casey: "Antarctica",
    Davis: "Antarctica",
    Mawson: "Antarctica",
    Palmer: "Antarctica",
    Rothera: "Antarctica",
    Troll: "Antarctica",
    Vostok: "Antarctica",
    Buenos_Aires: "Argentina",
    Cordoba: "Argentina",
    Salta: "Argentina",
    Jujuy: "Argentina",
    Tucuman: "Argentina",
    Catamarca: "Argentina",
    La_Rioja: "Argentina",
    San_Juan: "Argentina",
    Mendoza: "Argentina",
    San_Luis: "Argentina",
    Rio_Gallegos: "Argentina",
    Ushuaia: "Argentina",
    Pago_Pago: "Samoa (American)",
    Vienna: "Austria",
    Lord_Howe: "Australia",
    Macquarie: "Australia",
    Hobart: "Australia",
    Melbourne: "Australia",
    Sydney: "Australia",
    Broken_Hill: "Australia",
    Brisbane: "Australia",
    Lindeman: "Australia",
    Adelaide: "Australia",
    Darwin: "Australia",
    Perth: "Australia",
    Eucla: "Australia",
    Baku: "Azerbaijan",
    Barbados: "Barbados",
    Dhaka: "Bangladesh",
    Brussels: "Belgium",
    Sofia: "Bulgaria",
    Bermuda: "Bermuda",
    Brunei: "Brunei",
    La_Paz: "Bolivia",
    Noronha: "Brazil",
    Belem: "Brazil",
    Fortaleza: "Brazil",
    Recife: "Brazil",
    Araguaina: "Brazil",
    Maceio: "Brazil",
    Bahia: "Brazil",
    Sao_Paulo: "Brazil",
    Campo_Grande: "Brazil",
    Cuiaba: "Brazil",
    Santarem: "Brazil",
    Porto_Velho: "Brazil",
    Boa_Vista: "Brazil",
    Manaus: "Brazil",
    Eirunepe: "Brazil",
    Rio_Branco: "Brazil",
    Thimphu: "Bhutan",
    Minsk: "Belarus",
    Belize: "Belize",
    St_Johns: "Canada",
    Halifax: "Canada",
    Glace_Bay: "Canada",
    Moncton: "Canada",
    Goose_Bay: "Canada",
    Toronto: "Canada",
    Nipigon: "Canada",
    Thunder_Bay: "Canada",
    Iqaluit: "Canada",
    Pangnirtung: "Canada",
    Winnipeg: "Canada",
    Rainy_River: "Canada",
    Resolute: "Canada",
    Rankin_Inlet: "Canada",
    Regina: "Canada",
    Swift_Current: "Canada",
    Edmonton: "Canada",
    Cambridge_Bay: "Canada",
    Yellowknife: "Canada",
    Inuvik: "Canada",
    Dawson_Creek: "Canada",
    Fort_Nelson: "Canada",
    Whitehorse: "Canada",
    Dawson: "Canada",
    Vancouver: "Canada",
    Cocos: "Cocos (Keeling) Islands",
    Zurich: "Switzerland",
    Abidjan: "Côte d'Ivoire",
    Rarotonga: "Cook Islands",
    Santiago: "Chile",
    Punta_Arenas: "Chile",
    Easter: "Chile",
    Shanghai: "China",
    Urumqi: "China",
    Bogota: "Colombia",
    Costa_Rica: "Costa Rica",
    Havana: "Cuba",
    Cape_Verde: "Cape Verde",
    Christmas: "Christmas Island",
    Nicosia: "Cyprus",
    Famagusta: "Cyprus",
    Prague: "Czech Republic",
    Berlin: "Germany",
    Copenhagen: "Denmark",
    Santo_Domingo: "Dominican Republic",
    Algiers: "Algeria",
    Guayaquil: "Ecuador",
    Galapagos: "Ecuador",
    Tallinn: "Estonia",
    Cairo: "Egypt",
    El_Aaiun: "Western Sahara",
    Madrid: "Spain",
    Ceuta: "Spain",
    Canary: "Spain",
    Helsinki: "Finland",
    Fiji: "Fiji",
    Stanley: "Falkland Islands",
    Chuuk: "Micronesia",
    Pohnpei: "Micronesia",
    Kosrae: "Micronesia",
    Faroe: "Faroe Islands",
    Paris: "France",
    London: "Britain (UK)",
    Tbilisi: "Georgia",
    Cayenne: "French Guiana",
    Gibraltar: "Gibraltar",
    Nuuk: "Greenland",
    Danmarkshavn: "Greenland",
    Scoresbysund: "Greenland",
    Thule: "Greenland",
    Athens: "Greece",
    South_Georgia: "South Georgia & the South Sandwich Islands",
    Guatemala: "Guatemala",
    Guam: "Guam",
    Bissau: "Guinea-Bissau",
    Guyana: "Guyana",
    Hong_Kong: "Hong Kong",
    Tegucigalpa: "Honduras",
    "Port-au-Prince": "Haiti",
    Budapest: "Hungary",
    Jakarta: "Indonesia",
    Pontianak: "Indonesia",
    Makassar: "Indonesia",
    Jayapura: "Indonesia",
    Dublin: "Ireland",
    Jerusalem: "Israel",
    Kolkata: "India",
    Calcutta: "India",
    Chagos: "British Indian Ocean Territory",
    Baghdad: "Iraq",
    Tehran: "Iran",
    Reykjavik: "Iceland",
    Rome: "Italy",
    Jamaica: "Jamaica",
    Amman: "Jordan",
    Tokyo: "Japan",
    Nairobi: "Kenya",
    Bishkek: "Kyrgyzstan",
    Tarawa: "Kiribati",
    Kanton: "Kiribati",
    Kiritimati: "Kiribati",
    Pyongyang: "Korea (North)",
    Seoul: "Korea (South)",
    Almaty: "Kazakhstan",
    Qyzylorda: "Kazakhstan",
    Qostanay: "Kazakhstan",
    Aqtobe: "Kazakhstan",
    Aqtau: "Kazakhstan",
    Atyrau: "Kazakhstan",
    Oral: "Kazakhstan",
    Beirut: "Lebanon",
    Colombo: "Sri Lanka",
    Monrovia: "Liberia",
    Vilnius: "Lithuania",
    Luxembourg: "Luxembourg",
    Riga: "Latvia",
    Tripoli: "Libya",
    Casablanca: "Morocco",
    Monaco: "Monaco",
    Chisinau: "Moldova",
    Majuro: "Marshall Islands",
    Kwajalein: "Marshall Islands",
    Yangon: "Myanmar (Burma)",
    Ulaanbaatar: "Mongolia",
    Hovd: "Mongolia",
    Choibalsan: "Mongolia",
    Macau: "Macau",
    Martinique: "Martinique",
    Malta: "Malta",
    Mauritius: "Mauritius",
    Maldives: "Maldives",
    Mexico_City: "Mexico",
    Cancun: "Mexico",
    Merida: "Mexico",
    Monterrey: "Mexico",
    Matamoros: "Mexico",
    Mazatlan: "Mexico",
    Chihuahua: "Mexico",
    Ojinaga: "Mexico",
    Hermosillo: "Mexico",
    Tijuana: "Mexico",
    Bahia_Banderas: "Mexico",
    Kuala_Lumpur: "Malaysia",
    Kuching: "Malaysia",
    Maputo: "Mozambique",
    Windhoek: "Namibia",
    Noumea: "New Caledonia",
    Norfolk: "Norfolk Island",
    Lagos: "Nigeria",
    Managua: "Nicaragua",
    Amsterdam: "Netherlands",
    Oslo: "Norway",
    Kathmandu: "Nepal",
    Nauru: "Nauru",
    Niue: "Niue",
    Auckland: "New Zealand",
    Chatham: "New Zealand",
    Panama: "Panama",
    Lima: "Peru",
    Tahiti: "French Polynesia",
    Marquesas: "French Polynesia",
    Gambier: "French Polynesia",
    Port_Moresby: "Papua New Guinea",
    Bougainville: "Papua New Guinea",
    Manila: "Philippines",
    Karachi: "Pakistan",
    Warsaw: "Poland",
    Miquelon: "St Pierre & Miquelon",
    Pitcairn: "Pitcairn",
    Puerto_Rico: "Puerto Rico",
    Gaza: "Palestine",
    Hebron: "Palestine",
    Lisbon: "Portugal",
    Madeira: "Portugal",
    Azores: "Portugal",
    Palau: "Palau",
    Asuncion: "Paraguay",
    Qatar: "Qatar",
    Reunion: "Réunion",
    Bucharest: "Romania",
    Belgrade: "Serbia",
    Kaliningrad: "Russia",
    Moscow: "Russia",
    Simferopol: "Russia",
    Kirov: "Russia",
    Volgograd: "Russia",
    Astrakhan: "Russia",
    Saratov: "Russia",
    Ulyanovsk: "Russia",
    Samara: "Russia",
    Yekaterinburg: "Russia",
    Omsk: "Russia",
    Novosibirsk: "Russia",
    Barnaul: "Russia",
    Tomsk: "Russia",
    Novokuznetsk: "Russia",
    Krasnoyarsk: "Russia",
    Irkutsk: "Russia",
    Chita: "Russia",
    Yakutsk: "Russia",
    Khandyga: "Russia",
    Vladivostok: "Russia",
    "Ust-Nera": "Russia",
    Magadan: "Russia",
    Sakhalin: "Russia",
    Srednekolymsk: "Russia",
    Kamchatka: "Russia",
    Anadyr: "Russia",
    Riyadh: "Saudi Arabia",
    Guadalcanal: "Solomon Islands",
    Mahe: "Seychelles",
    Khartoum: "Sudan",
    Stockholm: "Sweden",
    Singapore: "Singapore",
    Paramaribo: "Suriname",
    Juba: "South Sudan",
    Sao_Tome: "Sao Tome & Principe",
    El_Salvador: "El Salvador",
    Damascus: "Syria",
    Grand_Turk: "Turks & Caicos Is",
    Ndjamena: "Chad",
    Kerguelen: "French Southern & Antarctic Lands",
    Bangkok: "Thailand",
    Dushanbe: "Tajikistan",
    Fakaofo: "Tokelau",
    Dili: "East Timor",
    Ashgabat: "Turkmenistan",
    Tunis: "Tunisia",
    Tongatapu: "Tonga",
    Istanbul: "Turkey",
    Funafuti: "Tuvalu",
    Taipei: "Taiwan",
    Kiev: "Ukraine",
    Uzhgorod: "Ukraine",
    Zaporozhye: "Ukraine",
    Wake: "US minor outlying islands",
    New_York: "United States",
    Detroit: "United States",
    Louisville: "United States",
    Monticello: "United States",
    Indianapolis: "United States",
    Vincennes: "United States",
    Winamac: "United States",
    Marengo: "United States",
    Petersburg: "United States",
    Vevay: "United States",
    Chicago: "United States",
    Tell_City: "United States",
    Knox: "United States",
    Menominee: "United States",
    Center: "United States",
    New_Salem: "United States",
    Beulah: "United States",
    Denver: "United States",
    Boise: "United States",
    Phoenix: "United States",
    Los_Angeles: "United States",
    Anchorage: "United States",
    Juneau: "United States",
    Sitka: "United States",
    Metlakatla: "United States",
    Yakutat: "United States",
    Nome: "United States",
    Adak: "United States",
    Honolulu: "United States",
    Montevideo: "Uruguay",
    Samarkand: "Uzbekistan",
    Tashkent: "Uzbekistan",
    Caracas: "Venezuela",
    Ho_Chi_Minh: "Vietnam",
    Efate: "Vanuatu",
    Wallis: "Wallis & Futuna",
    Apia: "Samoa (western)",
    Johannesburg: "South Africa",
    Antigua: "Antigua & Barbuda",
    Anguilla: "Anguilla",
    Luanda: "Angola",
    McMurdo: "Antarctica",
    DumontDUrville: "Antarctica",
    Syowa: "Antarctica",
    Aruba: "Aruba",
    Mariehamn: "Åland Islands",
    Sarajevo: "Bosnia & Herzegovina",
    Ouagadougou: "Burkina Faso",
    Bahrain: "Bahrain",
    Bujumbura: "Burundi",
    "Porto-Novo": "Benin",
    St_Barthelemy: "St Barthelemy",
    Kralendijk: "Caribbean NL",
    Nassau: "Bahamas",
    Gaborone: "Botswana",
    "Blanc-Sablon": "Canada",
    Atikokan: "Canada",
    Creston: "Canada",
    Kinshasa: "Congo (Dem. Rep.)",
    Lubumbashi: "Congo (Dem. Rep.)",
    Bangui: "Central African Rep.",
    Brazzaville: "Congo (Rep.)",
    Douala: "Cameroon",
    Curacao: "Curaçao",
    Busingen: "Germany",
    Djibouti: "Djibouti",
    Dominica: "Dominica",
    Asmara: "Eritrea",
    Addis_Ababa: "Ethiopia",
    Libreville: "Gabon",
    Grenada: "Grenada",
    Guernsey: "Guernsey",
    Accra: "Ghana",
    Banjul: "Gambia",
    Conakry: "Guinea",
    Guadeloupe: "Guadeloupe",
    Malabo: "Equatorial Guinea",
    Zagreb: "Croatia",
    Isle_of_Man: "Isle of Man",
    Jersey: "Jersey",
    Phnom_Penh: "Cambodia",
    Comoro: "Comoros",
    St_Kitts: "St Kitts & Nevis",
    Kuwait: "Kuwait",
    Cayman: "Cayman Islands",
    Vientiane: "Laos",
    St_Lucia: "St Lucia",
    Vaduz: "Liechtenstein",
    Maseru: "Lesotho",
    Podgorica: "Montenegro",
    Marigot: "St Martin (French)",
    Antananarivo: "Madagascar",
    Skopje: "North Macedonia",
    Bamako: "Mali",
    Saipan: "Northern Mariana Islands",
    Nouakchott: "Mauritania",
    Montserrat: "Montserrat",
    Blantyre: "Malawi",
    Niamey: "Niger",
    Muscat: "Oman",
    Kigali: "Rwanda",
    St_Helena: "St Helena",
    Ljubljana: "Slovenia",
    Longyearbyen: "Svalbard & Jan Mayen",
    Bratislava: "Slovakia",
    Freetown: "Sierra Leone",
    San_Marino: "San Marino",
    Dakar: "Senegal",
    Mogadishu: "Somalia",
    Lower_Princes: "St Maarten (Dutch)",
    Mbabane: "Eswatini (Swaziland)",
    Lome: "Togo",
    Port_of_Spain: "Trinidad & Tobago",
    Dar_es_Salaam: "Tanzania",
    Kampala: "Uganda",
    Midway: "US minor outlying islands",
    Vatican: "Vatican City",
    St_Vincent: "St Vincent",
    Tortola: "Virgin Islands (UK)",
    St_Thomas: "Virgin Islands (US)",
    Aden: "Yemen",
    Mayotte: "Mayotte",
    Lusaka: "Zambia",
    Harare: "Zimbabwe",
  };
  return timeZoneCityToCountry;
};

export const getCountryName = () => {
    var userRegion;
    var userCity;
    var userCountry;
    var userTimeZone;

    if (Intl) {
      userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      var tzArr = userTimeZone.split("/");
      userRegion = tzArr[0];
      userCity = tzArr[tzArr.length - 1];
      userCountry = getTimeZoneRegionToCountry()[userCity];
    }

    // console.log("Time Zone:", userTimeZone);
    // console.log("Region:", userRegion);
    // console.log("City:", userCity);
    // console.log("Country:", userCountry);
    return userCountry;
}

export const getLocalRegion = () => {
  const locale = new Intl.Locale(navigator.language);
  return locale?.region;
}

export const playerVolume = {
  "multiplier" : 5,
  "defaultLevel" : 50
}

export const ICON_BLACK_COLOR = {
  iconBlackColor: "rgba(0, 0, 0, 0.87)"
};

export const PAGE_CONSTS = {
  category: "category",
  channels: "channels",
};

export const MaxItemCount = 5

export const maxInteger = 999999
  
  
export const aroundTimeBand = (sec) => {
  if (sec <= 60) {
    return "0-1";
  } else if (sec > 60 && sec <= 300) {
    return "1-5";
  } else if (sec > 300 && sec <= 600) {
    return "6-10";
  } else if (sec > 600 && sec <= 3660) {
    let min = sec / 60;
    let nearestInt = Math.ceil(min / 10);

    let maxValue = nearestInt * 10;

    return `${maxValue - 9}-${maxValue}`;
  } else if (sec > 3660) {
    return "61+";
  }
};


export const ClassDurationTimeBand = min => {
  if (min <= 60) {
      //let min = sec / 60;
      if (min === 0) {
          return "0-1"
      }
      let nearestInt = Math.ceil(min / 10);

      let maxValue = nearestInt * 10;

      return `${maxValue === 10 ? 0 : maxValue - 9}-${maxValue}`;
  } else if (min > 60) {
      return "61+";
  }
};

export const getEventTypeWord = (eventType) => {
  if (eventType === EventTypes.Live) {
    return EventTypeFullName.LIVE
  } else if (eventType === EventTypes.SL) {
    return EventTypeFullName.SIMULATED_LIVE
  } else if (eventType === EventTypes.SVOD) {
    return EventTypeFullName.SVOD_EVENT
  }
  return
}

export const LAYOUT_TYPE = {
  grid: "grid",
  list: "list"
}

export const changeFavicon = (newFaviconURL) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = newFaviconURL;
}

export function getTenantSpecificConfigurations() {
  try {
    return require('../assets/ClubManager/config.js');
  } catch (error) {
    return require('../ClubManager/config.js');
  }
}

export function getTenantSpecificActions() {
  try {
    return require('../assets/ClubManager/action');
  } catch (error) {
    return require('../ClubManager/action');
  }
}

export const getKey = () => {
  return process.env.REACT_APP_API_CLIENT_SECRET;
};

export const getRecentSearches = () => {
  const recentSearches = JSON.parse(localStorage.getItem(LocalStorageKeys.recentSearches)) || []
  return recentSearches
}