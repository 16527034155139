import React from "react"

function Clearsearch(){
    const color = (require("../assets/config.json")).primaryColor?.slice(1)

    const src = `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" viewBox="0 0 28 30">
    <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <circle cx="14" cy="14" r="14" fill="#C7C7CC"/>
        <path fill="#FFF" fill-opacity=".92" fillRule="nonzero" d="M10.57 8.855l3.43 3.43 3.43-3.43c.442-.442 1.14-.471 1.616-.088l.099.088c.473.474.473 1.242 0 1.715L15.715 14l3.43 3.43c.473.473.473 1.241 0 1.715-.474.473-1.242.473-1.715 0L14 15.715l-3.43 3.43c-.442.442-1.14.471-1.616.088l-.099-.088c-.473-.474-.473-1.242 0-1.715l3.43-3.43-3.43-3.43c-.473-.473-.473-1.241 0-1.715.474-.473 1.242-.473 1.715 0z"/>
    </g>
</svg>`

    return (
    <img src={`data:image/svg+xml;utf8,${src}`} 
        style={{
        cursor: "pointer",
        height: "23px",
        width: "23px",
        }}
        alt="clear"
    />
    )
}

export default Clearsearch
